import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Training } from '../../shared/training';
import { Meldung } from '../../shared/meldung';
import { Spieler } from 'src/app/shared/spieler';
import { DialogService } from '../../service/dialog.service';
// import Swal from 'sweetalert2';
import Swal from 'sweetalert2'

@Component({
  selector: 'bm-training-details',
  templateUrl: './training-details.component.html',
  styleUrls: ['./training-details.component.css']
})
export class TrainingDetailsComponent implements OnInit {
  training$: Observable<Training>
  id: number;
  adminmode: boolean;
  alleSpieler: Spieler[];
  isSpiel = false;

  heute = new Date();
  training: Training;
  showAnmeldebuttons= true;
  zeitAbgelaufen: boolean;
  countSpieler: number;
  dabei: number = 0;
  raus: number = 0;
  typ: string = "training";

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: DialogService
  ) {
    this.adminmode = bs.admin();

   }

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;

    const id = params.get('id');
    this.id = parseInt(params.get('id'));

    this.bs.getSingleTraining(id).subscribe(res => {
      if(res) {
        this.training = res
        this.isRechtzeitig(res)
        this.count(this.training.spieler);
        //console.log("leute "+ this.training.spieler.length);
        this.countSpieler = this.training.spieler.length
      }

    });
    this.training$ = this.bs.getSingleTraining(id);
    //this.isAnmeldung()

    //spieler holen für dropdown
    this.bs.getAllSpieler(false, true).subscribe(res => {
      if(res) {
        //console.log(res);
        this.alleSpieler = res;

      }
    })
  }

  count(array: Spieler[]): void {
    this.dabei = 0;
    this.raus = 0;
    for(let i = 0; i<array.length; i++ ) {
      //console.log(array[i]);
      //Anmeldebuttons verschwinden, wenn schon eine Meldung vorliegt
      if(array[i].idspieler == parseInt(localStorage.getItem('auth'))) {this.showAnmeldebuttons = false }
      if(array[i].dabei == 1) {
        this.dabei +=1
      } else if(array[i].dabei==2) {
        this.raus +=1
      }
    }

  }



  isRechtzeitig(training: Training) {
    //anmeldefrist in h
    let abmeldefrist = 19;
    let millisekunden = abmeldefrist * 3600000
    //console.log("local: " + this.heute.getTime());
    //console.log(training.zeit);
    let eventDatum = new Date(training.datum+"T"+training.zeit)
    //console.log("event: " + eventDatum.getTime() );
    if((eventDatum.getTime() - millisekunden ) < this.heute.getTime()) {
      this.zeitAbgelaufen = true;
      //console.log("zu spät");
    }
  }

  addItem(newItem: string) {
    console.log(newItem);
    this.ngOnInit();
  }

  async melde(val: number) {
    const params = this.route.snapshot.paramMap;
    const id = parseInt(params.get('id'));
    const spieler = parseInt(localStorage.getItem('auth'));
    let meldung = new Meldung();
    meldung.table = "vt_training_spieler"
    meldung.idspieler = spieler;
    meldung.idevent = id;
    meldung.status = val;
    await this.bs.melde(meldung).toPromise().then(async res => {
      if(res) {
        console.log(res);
        await Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Okay!', text: 'Meldung erfolgreich', icon: 'success', });
        this.ngOnInit()
      }
    })
  }

  async openEdit() {
    const result = await this.openUpdateDialog();
    console.log('result', result);
  }

  async openUpdateDialog(): Promise<any> {
    this.dialog.openTraining(this.training)
  }


  openDialog() {
    let table = 'training';
    if(this.adminmode) {
    if(confirm('Training wirklich löschen?')) {
      this.bs.removeTraining(this.id).subscribe(
       res => this.router.navigate(
         ['/home']
       )
      );
    }
  }
  }

  test() {
    console.log("test");
    Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Success!', text: 'Sweet Alert Toast', icon: 'success', });
  }  showMessage(){
    Swal.fire({ text: 'Hello!', icon: 'success'});
  }

  convertDate(s: string): string {

    s = s.replace('-',"")
    s = s.replace('-',"")
    s = s.replace(':',"")
    s = s.replace(':',"")

    s = s.replace('.',"")
    console.log(s);

    return s
  }

  //generiert trainingsstart
  makeDate():string {
    let datum = this.training.datum.replace('-', '')
    datum += 'T'+ this.training.zeit +'Z'
    datum = datum.replace("-","")
    datum = datum.replace(":","")
    datum = datum.replace(":","")

    console.log(datum);
    return datum
  }



  dateToString(d: Date):string {
    console.log("ein: " + d);
    let is = d.toISOString()
    console.log(is);

    return this.convertDate(is)
  }

  dateToEndDate(start: boolean): string {

    let d = this.training.datum +"T" + this.training.zeit
    let da = new Date(d)
    console.log(da);


    if(start) {
      da.setHours(da.getHours()+1)
    } else {
      da.setHours(da.getHours()+3)
      da.setSeconds(0)
      console.log(da);
    }
    return this.dateToString(da)

  }



  getICS(): string {
    const cal = [
      'data:text/calendar;charset=utf8,',
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTAMP:' + this.dateToString( new Date()),
      'DTSTART:' + this.dateToEndDate(true),
      'DTEND:' + this.dateToEndDate(false),
      'SUMMARY: Training',
      `DESCRIPTION:${this.training.zusatz.replace(/[\n\r]/g, '')}`,
      //'LOCATION:' + this.location,
      'UID:' + this.training.idTraining,
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');
    return cal
  }

  addToCalendar(): void {
    this.makeDate()
    console.log(this.getICS());

    window.open(this.getICS());
  }
}
