import { Component, OnInit } from '@angular/core';
import { Spieler } from '../shared/spieler';
import { BookStoreService } from '../shared/book-store.service';
import { Kontostand } from '../shared/kontostand';
import { Kontoauszug } from '../shared/kontoauszug';
import { DialogService } from '../service/dialog.service';
import { FormBuilder } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'bm-profil',
  templateUrl: './profil.component.html',
  styleUrls: ['./profil.component.css']
})
export class ProfilComponent implements OnInit {
  spieler: Spieler;
  kontostand : Kontostand;
  kontoauszug: Kontoauszug[];
  editmode: boolean = false;
  admin: boolean;


  constructor(
    private bs: BookStoreService,
    private dialog: DialogService,
    private fb: FormBuilder
  ) {
    this.admin = this.bs.admin()
  }


  ngOnInit(): void {
    this.loadData();
  }



  getSpielerId(): string {
    return localStorage.getItem('auth')
  }

  loadData() {
    this.bs.getSpieler(this.getSpielerId()).subscribe(res => this.spieler = res);
    this.bs.getKontostand(parseInt(this.getSpielerId()), 3 ).subscribe(res => {
      if(res) {
        console.log(res);
        this.kontostand = res;
        let betrag = res.betrag
        if(betrag >= 0) {
          Swal.fire({
            icon: 'info',
            title: '',
            text: `Dein Kontostand beträgt ${this.kontostand.kontostand} €`,
            footer: ''
          })
        } else {
          Swal.fire({
            icon: 'warning',
            title: '',
            text: `Dein Kontostand beträgt ${this.kontostand.kontostand} €`,
            footer: ''
          })
        }
      }
    });
    this.bs.getKontoauszug(parseInt(this.getSpielerId()),3 ).subscribe(res => this.kontoauszug = res);
  }


  edit() {
    this.editmode = !this.editmode
  }

  abwesenheit() {
    this.dialog.openAbwesenheit(this.spieler.name)
  }
}
