import { Component,ViewEncapsulation, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spieltag } from '../../shared/spieltag';
import { Verein } from '../../shared/verein';
import { DialogService } from '../../service/dialog.service';

interface Typ {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'bm-create-spieltag',
  templateUrl: './create-spieltag.component.html',
  styleUrls: ['./create-spieltag.component.css'],
  encapsulation: ViewEncapsulation.None,
})
export class CreateSpieltagComponent implements OnInit {

  spielForm: FormGroup;
  selectedValue: string;
  labelPosition: 'before' | 'after' = 'after';
  datum: string;
  vereine$: Observable<Verein[]>
  vereine: Verein[];
  vereineSearch: Verein[];
  gegner : Verein;
  spielort: number;
  text= '';

  formatLabel(value: number) {
    if (value >= 1000) {
      return Math.round(value / 1000) + 'k';
    }

    return value;
  }


  typ: Typ[] = [
    {value: 'Freundschaftsspiel', viewValue: 'Freundschaftsspiel'},
    {value: 'Meisterschaftsspiel', viewValue: 'Meisterschaftsspiel'},
    {value: 'Pokalspiel', viewValue: 'Pokalspiel'}
  ];


  constructor(
    private bs: BookStoreService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: DialogService
  ) { }

  ngOnInit(): void {
    this.vereine$ = this.bs.getAllVereine();
    this.bs.getAllVereine().subscribe(res => this.vereine = res);
    this.initForm();
  }

  private initForm() {
    if(this.spielForm) { return; }
    let team = this.bs.getTeam();
    this.spielForm = this.fb.group({
      //gegner: datum team spielort(number) vereinId
      gegnerTeam: [''],
      datum: [''],
      zeit: [],
      typ:[''],
      team: [team],
      kommentar: [],
      idspielort: [''],
      verein: ['', Validators.required]
    })
  }
  cancel() {
    this.spielForm.reset();
  }

  search(x: any) {
    // console.log('====================================');
    // console.log(x.target.value);
    // console.log(x.target.value.length);
    // console.log('====================================');

    if(x.target.value.length >= 3) {
      //this.bs.getAllVereineByName(x.target.value).subscribe(res => this.vereineSearch = res)
      this.vereine$ = this.bs.getAllVereineByName(x.target.value)
    }
  }



  selectVerein(event: any): void {
    let str: string[] =  event.split(';')
    this.bs.getVerein(parseInt(str[0])).toPromise().then((res : Verein) => {
      //this.gegner = res;
      this.gegner = new Verein(res.idVerein,res.name,res.logo,res.idspielort,res.ort,res.mannschaften,res.ah)
    })

  }

  submitForm() {
    //console.log(this.spielForm.value.verein);
    let verein = this.spielForm.value.verein.split(";");
    let vId = verein[0];
    //let idSpielort = verein[1];

    this.datum= this.spielForm.value.datum+' '+this.spielForm.value.zeit;
    //console.log(this.spielForm.value.idspielort);
    const formValue = this.spielForm.value;
    //console.log( this.spielForm.value );

    if(formValue.idspielort == 1) {
      this.spielort = 1
    }
    const newGame: Spieltag = {
      ...formValue,
      gegner: this.gegner.name + " "+ formValue.gegnerTeam,
      datum: this.datum,
      saison: this.bs.getCurSaison(),
      vereinId: vId,
      idspielort: this.gegner.idspielort
    }
    // console.log(newGame);
    this.dialog.openConfirmDialog(newGame.gegner + " speichern?").afterClosed().subscribe( res => {
      if(res) {
        this.bs.createSpieltag(newGame)
        this.router.navigate(['/home'])
      }
    })

  }

}
