<div class="container">
  <bm-form (newItemEvent)="addItem($event)"></bm-form>
<h2>Meldungen zum Training</h2>
<div  fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="flex-start">


<div fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%" *ngFor="let s of $spieler | async">
  <mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar>
            <ng-container *ngIf="s.bild; else elseBlock">
                <img class="ui mini image" src="../../../assets/spielerFotos/{{s.bild}}"> </ng-container>
        </div>


        <mat-card-title>{{ s.name + ', ' + s.vorname }}</mat-card-title>
        <mat-card-subtitle>Anzahl: {{s.anzahl}}
          <!-- <ng-container *ngFor="let i of [].constructor(s.anzahl)">
            <img class="ui mini image" src="../../../assets/icons/star.png">
          </ng-container> -->

        </mat-card-subtitle>
        <ng-container *ngFor="let i of [].constructor(s.anzahl)">
          <img class="star" src="../../../assets/icons/star.png">
        </ng-container>
    </mat-card-header>

</mat-card>

</div>




<ng-template #elseBlock><img class="ui tiny image " src="../../../assets/spielerFotos/ohne.jpg "></ng-template>


</div>

<!-- <div class="row">
    <div class="col-lg-6">
        <h2>Meldungen zum Training</h2>
        <canvas id="pichart"></canvas>
    </div>
</div> -->




<!-- <div *ngFor="let t of training">
    {{t.datum | date: "shortDate"}}
    <div>{{t.spieler.length}}</div>
    <ng-container *ngIf="t.spieler">
        <div *ngFor="let s of t.spieler">

        </div>

    </ng-container>

</div> -->
