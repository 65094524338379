

<div class="top">
  <button class="button"(click)="dreiMalZwanzig()">3x20 STRAFE</button>
  <div class="offen">Offene Beträge: {{summe}}€ </div>
</div>

<table mat-table [dataSource]="kontostaende" class="mat-elevation-z8">

    <!-- Name Column -->
    <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="vorname">
        <th mat-header-cell *matHeaderCellDef> Vorname </th>
        <td mat-cell *matCellDef="let element"> {{element.vorname}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="kontostand">
        <th mat-header-cell *matHeaderCellDef> Konstostand </th>
        <td mat-cell *matCellDef="let element"> {{element.kontostand}} €</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
