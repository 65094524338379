<div>
  <div class="streifen">
      <mat-icon id="close-icon" (click)="closeDialog()">close</mat-icon>
  </div>
  <div class="content-container">
      <span class="content-span full-width">{{data.message}}</span>

      <mat-form-field appearance="fill" class="example-form-field">
        <mat-label>Enter a date range</mat-label>
        <mat-date-range-input [formGroup]="range" [rangePicker]="rangePicker">
          <input matStartDate formControlName="start" placeholder="Start date">
          <input matEndDate formControlName="end" placeholder="End date">
        </mat-date-range-input>
        <mat-datepicker-toggle matSuffix [for]="rangePicker"></mat-datepicker-toggle>
        <mat-date-range-picker #rangePicker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button mat-raised-button color="primary" matDateRangePickerApply (click)="save($event)">Apply</button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
      <p>Selected range: {{range.value | json}}</p>
  </div>
  <button mat-raised-button id="yes-button" (click)="closeDialog()">Speichern</button>
</div>



