import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Training } from '../../shared/training';
import { FormBuilder } from '@angular/forms';
import { BookStoreService } from '../../shared/book-store.service';
import { DialogService } from 'src/app/service/dialog.service';



@Component({
  selector: 'bm-update-training',
  templateUrl: './update-training.component.html',
  styleUrls: ['./update-training.component.css']
})
export class UpdateTrainingComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
  public tr: Training,
  private fb: FormBuilder,
  private dialog: DialogService,
  private bs: BookStoreService,
  public dialogRef: MatDialogRef<UpdateTrainingComponent>) { }

  kat: string[] = ['training', 'kicken', 'laufen', 'biken', 'sonstiges'];

  trainForm = this.fb.group({
    datum: [this.tr.datum],
    zeit: [this.tr.zeit],
    zusatz: [this.tr.zusatz],
    pflicht: [this.tr.pflicht],
    kategorie: [this.tr.kategorie]
  });

  ngOnInit(): void {
  }

  save() {
    // console.log(this.tr);
    const training : Training = {
      idTraining: this.tr.idTraining,
     ...this.trainForm.value
    }
    this.bs.updateTraining(training).toPromise().then(res => {
      if(res) {
        this.dialog.openMessageDialog("Änderung gespeichert")
        this.closeDialog(true)
      }
      else {
        alert("Fehler")
      }
    })
  }

  closeDialog(val: boolean) {
    this.dialogRef.close(val);
  }

}
