<h1>Neues Spiel anlegen</h1>

<mat-card class="mat-card">
    <!--  gegner datum typ team spielort(number) vereinId-->
    <form [formGroup]="spielForm" (ngSubmit)="submitForm()">

        <section class="example-section">
            <label class="example-margin"></label>
            <mat-radio-group formControlName="team">
                <mat-radio-button class="example-margin" value="1">ERSTE</mat-radio-button>
                <mat-radio-button class="example-margin" value="2">ZWEITE</mat-radio-button>
                <mat-radio-button class="example-margin" value="3">DRITTE</mat-radio-button>
                <mat-radio-button class="example-margin" value="4">Alte Herren</mat-radio-button>
            </mat-radio-group>
        </section>

        <mat-form-field class="example-full-width" appearance="fill">
            <mat-label>Spieltyp</mat-label>
            <mat-select name="typ" formControlName="typ">
                <mat-option *ngFor="let t of typ" [value]="t.value">
                    {{t.viewValue}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <form class="example-form">
          <mat-form-field class="example-full-width" appearance="fill">
              <mat-label>Vereinsauswahl einschränken</mat-label>
              <input matInput #message minlength="3" maxlength="10" (keyup.backspace)="search($event)" (keyup)="search($event)" placeholder=" Vereinsname ">
              <mat-hint align="start "><strong>Eingabe >= 3 Buchstaben</strong> </mat-hint>
            </mat-form-field>
        </form>

<ng-container *ngIf="vereine$ | async as vereinList ">
        <mat-form-field class="example-full-width" appearance="fill">
          <mat-label>Verein</mat-label>
          <mat-select name="typ " formControlName="verein" (ngModelChange)="selectVerein($event) ">
            <mat-option *ngFor="let v of vereinList " [value]="v.idVerein + ';'+ v.idspielort ">
              {{v.name}}
          </mat-option>
          </mat-select>
      </mat-form-field>
    </ng-container>

            <!-- <ng-container *ngIf="vereine$ | async as vereinList ">
                <mat-form-field class="example-full-width" appearance="fill ">
                    <mat-label>Verein</mat-label>
                    <mat-select name="typ " formControlName="verein" (ngModelChange)="selectVerein($event) ">
                        <mat-option *ngFor="let v of vereinList " [value]="v.idVerein + ';'+ v.idspielort ">
                            {{v.name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container> -->



        <ng-container *ngIf="gegner ">
            <section class="example-section ">
                <label class="example-margin "></label>
                <mat-radio-group formControlName="gegnerTeam">
                    <h3>{{gegner.name}}</h3>
                    <mat-radio-button *ngIf="gegner.mannschaften>=1" class="example-margin" value="I">erste</mat-radio-button>
                    <mat-radio-button *ngIf="gegner.mannschaften >=2" class="example-margin" value="II">zweite</mat-radio-button>
                    <mat-radio-button *ngIf="gegner.mannschaften >=3 " class="example-margin" value="III">Dritte</mat-radio-button>
                    <mat-radio-button *ngIf="gegner.mannschaften >=4 " class="example-margin" value="IV">Vierte</mat-radio-button>

                    <mat-radio-button *ngIf="gegner.ah == 1" class="example-margin" value="AH">AH</mat-radio-button>
                </mat-radio-group>
            </section>

        </ng-container>

        <p>
            <mat-form-field>
                <mat-label>Datum</mat-label>
                <input type="date" matInput formControlName="datum">
            </mat-form-field>
        </p>

        <p>
            <mat-form-field>
                <mat-label>Anstoss</mat-label>
                <input type="time" matInput formControlName="zeit">
            </mat-form-field>
        </p>

        <!--1: Heimspiel / 24: noch unbekannt-->
        <section class="example-section">
            <label class="example-margin"></label>
            <mat-radio-group formControlName="idspielort">
                <mat-radio-button class="example-margin" value="1">Heimspiel</mat-radio-button>
                <mat-radio-button class="example-margin" value="24">Auswärtsspiel</mat-radio-button>
            </mat-radio-group>
        </section>

        <section>
            <div class="example-button-row">

                <button mat-raised-button color="accent" (click)="cancel()">Verwerfen</button>
                <button type="submit" mat-raised-button color="primary" [disabled]="!spielForm.valid">Speichern</button>
            </div>
        </section>

    </form>
</mat-card>
