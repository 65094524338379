import { Injectable } from '@angular/core';
import { BookStoreService } from './book-store.service';
import { Spieler } from './spieler';

import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { throwError, Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Login } from './login';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: Spieler;
  private ownApi = 'https://svezwei.de/assets/api';
  // private ownApi = 'http://localhost/svedrei/php';



  constructor(
    private bs: BookStoreService,
    private http: HttpClient,
    private router: Router
  ) { }


  loginNew(login: Login) {
    return this.http.post<Spieler>(`${this.ownApi}/loginPost.php`, login)
  }


  public isLoggedIn() {
    return localStorage.getItem('auth')!==null;
  }

  public logout() {
    localStorage.removeItem('auth');
    localStorage.removeItem('admin');
    localStorage.removeItem('team');
    localStorage.removeItem('vl');

  }

  public isAdmin() {
    if(localStorage.getItem('admin') === 'true') {
      return true
    }
    return false
  }

}
