import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { BookStoreService } from '../shared/book-store.service';
import { Meldung } from '../shared/meldung';
import { Termin } from '../shared/termin';
import { DialogService } from '../service/dialog.service';
import { Spieler } from '../shared/spieler';

@Component({
  selector: 'bm-termindetails',
  templateUrl: './termindetails.component.html',
  styleUrls: ['./termindetails.component.css']
})
export class TermindetailsComponent implements OnInit {


  id:number;
  termin: Termin;
  spieler: Spieler[]
  typ = "termin"

  showButton: boolean = true

  constructor(
    private bs: BookStoreService,
    private router: Router,
    public dialog: DialogService
  ) {
  }

  ngOnInit(): void {
    //const params = this.route.snapshot.paramMap;
    //this.id = parseInt(params.get('id'));
    this.init()
  }

  init(){
    this.bs.getTermin(this.id).subscribe(res => {
      this.termin = res
      console.log(this.termin);

      // console.log('====================================');
      // console.log(this.termin.spieler);
      this.spieler = this.termin.spieler
      const id = parseInt(localStorage.getItem('auth'))

      for(let s of this.spieler) {
        if(s.idspieler == id) this.showButton = false
      }
      // console.log('====================================');
    })
  }

  melde(id:number) {

    let meldung = new Meldung()
    meldung.table = "vtspielerveranstaltung";
    meldung.idspieler = parseInt(localStorage.getItem('auth'));
    meldung.idevent = this.id
    meldung.status = id;
    this.bs.melde(meldung).toPromise().then(res => {
      console.log("res: "+res);

      if(res == '1') {
        this.dialog.openMessageDialog("okay")
        this.showButton = false
        this.ngOnInit();
      }
    })
  }

  neuladen(){
    console.log("neu");

    this.ngOnInit();
  }

  parent(data: Meldung) {
    console.log(data);
    let text = "";
    //this.bs.updateMeldung(data).subscribe(res => console.log(res))
  }

}
