<mat-card *ngIf="i%2==0 && !heute" class="example-card" [ngClass]="{'heute': heute }" [@move]="index">

    <mat-card-header>
        <div mat-card-avatar> <img class="ui mini image" src="../../../assets/icons/{{training.kategorie}}.svg"></div>
        <mat-card-title>{{training.kategorie | uppercase}} </mat-card-title>
        <mat-card-subtitle *ngIf="training.team != 0"> {{training.team}}. Mannschaft</mat-card-subtitle>
        <mat-card-subtitle *ngIf="training.team == 0"> FÜR ALLE</mat-card-subtitle>

        <mat-card-subtitle> <b>{{training.datum | date: 'mediumDate'}} um {{training.zeit}} Uhr.</b></mat-card-subtitle>

        <div *ngIf="[training.pflicht] == 0">

            <h4>Freiwillig!</h4>
        </div>


    </mat-card-header>
    <mat-card-content>
        <p>
            {{training.zusatz}}
        </p>
    </mat-card-content>

    <!--<mat-card-actions>
      <button mat-fab color="primary">Primary</button>
  </mat-card-actions> -->
</mat-card>

<mat-card *ngIf="i%2==1 && !heute" class="example-card odd" [ngClass]="{'heute': heute }">

    <mat-card-header>


        <div mat-card-avatar> <img class="ui mini image" src="../../../assets/icons/{{training.kategorie}}.svg"></div>
        <mat-card-title>{{training.kategorie | uppercase}} </mat-card-title>
        <mat-card-subtitle> {{training.team}}. Mannschaft</mat-card-subtitle>
        <mat-card-subtitle> <b>{{training.datum | date: 'mediumDate'}} um {{training.zeit}} Uhr.</b></mat-card-subtitle>

        <div *ngIf="[training.pflicht] == 0">

            <p>Freiwillig!</p>
        </div>


    </mat-card-header>
    <mat-card-content>
        <p>
            {{training.zusatz}}
        </p>
    </mat-card-content>

    <!--<mat-card-actions>
    <button mat-fab color="primary">Primary</button>
</mat-card-actions> -->
</mat-card>

<mat-card *ngIf="heute" class="example-card" [ngClass]="{'heute': heute }">

    <mat-card-header>


        <div mat-card-avatar> <img class="ui mini image" src="../../../assets/icons/{{training.kategorie}}.svg"></div>
        <mat-card-title>{{training.kategorie | uppercase}} </mat-card-title>
        <mat-card-subtitle> {{training.team}}. Mannschaft</mat-card-subtitle>
        <mat-card-subtitle> <b>{{training.datum | date: 'mediumDate'}} um {{training.zeit}} Uhr.</b></mat-card-subtitle>

        <div *ngIf="[training.pflicht] == 0">

            <h4>Freiwillig!</h4>
        </div>


    </mat-card-header>
    <mat-card-content>
        <p>
            {{training.zusatz}}
        </p>
    </mat-card-content>

    <!--<mat-card-actions>
  <button mat-fab color="primary">Primary</button>
</mat-card-actions> -->
</mat-card>
