import { Injectable } from '@angular/core';
import { Spieltag } from '../shared/spieltag';

@Injectable({
  providedIn: 'root'
})
export class CalenderService {

  constructor() { }


  getCalenderEntry(spieltag: Spieltag): any {
    let calData = [
      'data:text/calendar;charset=utf8,',
      'BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DESCRIPTION:' + spieltag.kommentar,
      'DTSTART:' + spieltag.datum,
      'DTEND:' + spieltag.datum+120,
      //'LOCATION:' + this.location,
      //'SUMMARY:' + this.summary,
      'TRANSP:TRANSPARENT',
      'END:VEVENT',
      'END:VCALENDAR',
      'UID:' + spieltag.idspieltag,
      'DTSTAMP:' + new Date().toISOString(),
      'PRODID:website-1.0'
    ].join('\n');
    return calData
  }

}


