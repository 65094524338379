<h3>Übersicht</h3>




<div role="list">
    <div class="unterliste" *ngFor="let item of list; let i = index" role="listitem">
        {{item.gegner}}
        <div class=" uul" *ngFor="let dienst of item.dienst">
            <div class="platzdienst" *ngIf="dienst.value == 1"> Platzdienst: {{dienst.name}}
            </div>
            <div class="theke" *ngIf="dienst.value == 2"> Theke: {{dienst.name}}
            </div>
            <div class="beides" *ngIf="dienst.value == 12"> Platz & Theke: {{dienst.name}}
            </div>
        </div>

    </div>
</div>
