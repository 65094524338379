import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { map, switchMap } from 'rxjs/operators';
import { BookStoreService } from '../../shared/book-store.service';
import { Verein } from '../../shared/verein';

@Component({
  selector: 'bm-edit-verein',
  templateUrl: './edit-verein.component.html',
  styleUrls: ['./edit-verein.component.css']
})
export class EditVereinComponent implements OnInit {
  verein: Verein;

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  updateVerein(verein: Verein) {
    console.log(verein);
  }

  ngOnInit(): void {
    this.route.paramMap.pipe(
      map(params => parseInt(params.get('id'))),
      switchMap((id: number) => this.bs.getVerein(id))
    ).subscribe(verein => this.verein = verein)
  }

}
