import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { CanNavigateToAdminGuard } from './can-navigate-to-admin.guard';
import { SpielerListeComponent } from './spieler/spieler-liste/spieler-liste.component'
import { SpielerDetailsComponent } from './spieler/spieler-details/spieler-details.component';
import { SpieleComponent } from './spielbetrieb/spiele/spiele.component';
import { TrainingComponent } from './spielbetrieb/training/training.component';
import { TrainingDetailsComponent } from './spielbetrieb/training-details/training-details.component';
import { SpieltagDetailsComponent } from './spielbetrieb/spieltag-details/spieltag-details.component';
import { AuthComponent } from './auth/auth.component';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth.guard';
import { LoginComponent } from './login/login.component';
import { CreateOrtComponent } from './admin/create-ort/create-ort.component';
import { VereineListeComponent } from './vereine-liste/vereine-liste.component';
import { CreateTrainingComponent } from './spielbetrieb/create-training/create-training.component';
import { CreateSpieltagComponent } from './spielbetrieb/create-spieltag/create-spieltag.component';
import { SettingsComponent } from './settings/settings.component';
import { CreateClubComponent } from './create-club/create-club.component';
import { VereinDetailsComponent } from './spielbetrieb/verein-details/verein-details.component';
import { EditVereinComponent } from './spielbetrieb/edit-verein/edit-verein.component';
import { AddSpielDatenComponent } from './spielbetrieb/add-spiel-daten/add-spiel-daten.component';
// import { OrtComponent } from './spielbetrieb/ort/ort.component';
import { SchiedsrichterListeComponent } from './schiri/schiedsrichter-liste/schiedsrichter-liste.component';
import { SchiedsrichterDetailsComponent } from './schiri/schiedsrichter-details/schiedsrichter-details.component';
import { RegisterComponent } from './register/register.component'
import { IsLoggedInGuard } from './is-logged-in.guard';
import { ProfilComponent } from './profil/profil.component';
import { BuchenComponent } from './kasse/buchen/buchen.component';
import { StrafenComponent } from './spielbetrieb/strafen/strafen.component';
import { KontostandListeComponent } from './kasse/kontostand-liste/kontostand-liste.component';
import { HelpComponent } from './help/help.component';
import { TermindetailsComponent } from './termindetails/termindetails.component';
import { SaisonListeComponent } from './saison-liste/saison-liste.component';
import { StatistikComponent } from './statistik/statistik/statistik.component';
import { CreateDataComponent } from './admin/create-data/create-data.component';

const routes: Routes = [
  { path: '', component: HomeComponent,canActivate: [AuthGuard]},
  { path: 'login', component: LoginComponent },
  { path: 'auth', component: AuthComponent },
  { path: 'admin', component: AdminComponent, canActivate: [AuthGuard] },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'strafen',
    component: StrafenComponent
  },
  {
    path: 'spiele',
    component: SpieleComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'saisonliste',
    component: SaisonListeComponent,
  },
  {
    path: 'createSpieltag',
    component: CreateSpieltagComponent,
    canActivate: [CanNavigateToAdminGuard]

  },
  {
    path: 'createData',
    component: CreateDataComponent,
    canActivate: [CanNavigateToAdminGuard]

  },
  {
    path: 'spiele/:id',
    component: SpieltagDetailsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'createTraining',
    component: CreateTrainingComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'training',
    component: TrainingComponent
  },
  {
    path: 'training/:id',
    component: TrainingDetailsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'termin/:id',
    component: TermindetailsComponent
  },
  {
    path: 'statistik',
    component: StatistikComponent
  },
  // {
  //   path: 'books',
  //   loadChildren: () => import('./books/books.module').then(m => m.BooksModule)
  // },
  {
    path: 'spieler/:id',
    component: SpielerDetailsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'profil',
    component: ProfilComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'spieler',
    component: SpielerListeComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'createOrt',
    component: CreateOrtComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'verein',
    component: VereineListeComponent
  },
  {
    path: 'verein/:id',
    component: VereinDetailsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'verein/edit/:id',
    component: EditVereinComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'schiedsrichter',
    component: SchiedsrichterListeComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'schiedsrichter/:id',
    component: SchiedsrichterDetailsComponent,
    canActivate: [IsLoggedInGuard]
  },
  {
    path: 'createOrt',
    component: CreateOrtComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'createClub',
    component: CreateClubComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'addSpieldaten',
    component: AddSpielDatenComponent,
    canActivate: [IsLoggedInGuard]
  },
  // {
  //   path: 'ort/:id',
  //   component: OrtComponent,
  //   canActivate: [IsLoggedInGuard]
  // },
  {
    path: 'buchen',
    component: BuchenComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'kontostandliste',
    component: KontostandListeComponent,
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'help',
    component: HelpComponent
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: 'trikots',
    loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule),
    canActivate: [CanNavigateToAdminGuard]
  },
  {
    path: '**',
    component: HomeComponent,
    canActivate: [IsLoggedInGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes,
    { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
