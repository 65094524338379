
<div id="modal-content-wrapper">
  <header id="modal-header">
      <h1 id="modal-title">Neues Passwort eingeben</h1>
  </header>
  <section id="modal-body">

    <form class="example-form" [formGroup]="pwForm" novalidate>
      <h2>Spieler</h2>

      <div>id: {{idspieler}}</div>


            <mat-form-field class="example-full-width">
              <label for="password1">Vorname: </label>
              <input matInput id="password1" type="text" formControlName="password1">


            </mat-form-field>


            <mat-form-field class="example-full-width">
              <label for="password2">password1: </label>
              <input matInput id="password2" type="text" formControlName="password2">
            </mat-form-field>

            <footer id="modal-footer">
              <button [disabled]="pwForm.invalid" mat-raised-button id="modal-action-button" (click)="save()">
                  Do it
              </button>
              <button mat-raised-button id="modal-cancel-button" (click)="closeDialog(false)">
                  Go back
              </button>
          </footer>


    </form>
  </section>



</div>
