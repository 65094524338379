<mat-card class="example-result-card">

  <mat-card-content>
    <h2>Vorlauf auf dem Homescreen</h2>
    <p>
      hier kannst du einstellen, wie weit du in die Zukunft sehen möchtest auf
      dem Homescreen.
    </p>
    <div class="example-label-container">
      <label id="example-name-label" class="example-name-label">Wert</label>
      <label class="example-value-label">{{value}} Wochen</label>
    </div>
    <mat-slider
    thumbLabel
    [displayWith]="formatLabel"
    tickInterval="1000"
    step="1"
    min="2"
    max="20"
    [(ngModel)]="value"
    [vertical]="false"

    aria-label="units">
    </mat-slider>
  </mat-card-content>
</mat-card>

<mat-card *ngIf="admin">

  <div>
    <h2>Hier kannst du die Teamzugehörigkeit ändern</h2>
    <!-- Du bist angemeldet als {{labelPosition}} -->
    <p>
      hat noch keine großen Auswirkungen
    </p>
  </div>
  <div>
    <h2>Neue Spieler hinzufügen</h2>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Vorname" #vorname value="vorname">
  </mat-form-field>

  <mat-form-field class="example-full-width">
      <input matInput placeholder="Nachname" #name value="nachname">
  </mat-form-field>
  <button mat-stroked-button color="accent" class="btn-block" (click)="save(vorname.value +';'+ name.value)">speichern</button>

  </div>
</mat-card>

<section class="example-section">
  <label class="example-margin">Team:</label>
  <mat-radio-group [(ngModel)]="labelPosition">
    <mat-radio-button class="example-margin" [value]="1"  (click)="team($event)">
      Erste
    </mat-radio-button>
    <mat-radio-button class="example-margin" [value]="2"  (click)="team($event)">
       Zweite
    </mat-radio-button>
    <mat-radio-button class="example-margin" [value]="3"  (click)="team($event)">
      Dritte
    </mat-radio-button>
    <mat-radio-button class="example-margin" [value]="4"  (click)="team($event)">
      Alte Herren
    </mat-radio-button>
  </mat-radio-group>
</section>



<mat-card class="example-result-card">


  <div>
    <h2>Saison einstellen</h2>

    <h4>Saison einstellen</h4>
    <mat-form-field appearance="fill">
      <mat-label>Saison</mat-label>
      <mat-select>
        <mat-option *ngFor="let food of foods" [value]="food.value" (click)="chSeason(food.value)">
          {{food.viewValue}}
        </mat-option>
      </mat-select>
    </mat-form-field>
</div>
</mat-card>
