import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormArray, Validators, FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { BookStoreService } from 'src/app/shared/book-store.service';
import { Training, Training2 } from '../../shared/training';
import { TrainingDefault } from '../../shared/trainData';

import { DialogService } from '../../service/dialog.service';
// import Swal from 'sweetalert2/dist/sweetalert2.js';

@Component({
  selector: 'bm-create-training',
  templateUrl: './create-training.component.html',
  styleUrls: ['./create-training.component.css']
})
export class CreateTrainingComponent implements OnInit {

  trainForm: FormGroup;
  setting: TrainingDefault;
  day: number;
  days: FormGroup;


  constructor(
    private fb: FormBuilder,
    private bs: BookStoreService,
    private dialog: DialogService,
    private router: Router

  ) {
    this.days = fb.group({
      1: false,
      2: false,
      3: false,
      4: false,
      5: false,
    })
  }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    this.day = new Date().getDay()

    this.bs.getDefSetting().toPromise().then(res => {
      if(res) {
        this.setting = res;
      }
    });

    if(this.trainForm) { return; }

    this.trainForm = this.fb.group({
      date: [ '', Validators.required],
      time: ['19:00', Validators.required],
      team: ['3'],
      zusatz: [''],
      kategorie: ['training', Validators.required],
      pflicht:['1', Validators.required]
    })
  }

  submitForm() {
    if(!this.trainForm.value.team) {
      this.trainForm.value.team = localStorage.getItem('team');
    }
    const formValue = this.trainForm.value;
    const newTraining: Training = {
      ...formValue,
      saison: this.bs.getCurSaison()
    }
    //console.log(newTraining);

    this.dialog.openConfirmDialog("Training speichern?").afterClosed().subscribe( res => {
      if(res) {
        this.write(newTraining)
      }
    })
  }

  trainfactory(datum: Date) {
    // console.log(datum + " " + zeit);
    let year = datum.getFullYear()
    let month = datum.getMonth()+1
    let day = datum.getDate()
    // console.log(month);
    let saison = this.bs.getCurSaison();
    const newTraining: Training2 = {
      date: year+"-"+month+"-"+day,
      time: "19:00",
      zusatz: "",
      kategorie: "training",
      pflicht: 1,
      team: localStorage.getItem('team'),
      saison: saison
    }
    console.log(newTraining);
    this.dialog.openConfirmDialog("Training für " + datum.toLocaleDateString() + " speichern?").afterClosed().subscribe(res => {
      if(res){
        this.write2(newTraining)
      } else {
        // this.trainForm.reset()
      }
    })

  }
  getTageProMonat(val: number) {
    console.log(val);
    if(val == 0 || val  == 2 || val == 4 || val  == 6 || val == 7 || val == 9 ||  val ==11 ) {
      return 31
    } else if(val == 3 || val  == 5 || val  == 8 || val == 10) {
      return 30
    } else {
      return 28
    }
  }

  async saveWoche() {
    // tage ermitteln
    // datum erstellen und wochentag herausfinden
    //überprüfen, ob es schon ein training gibt
    // zeiten??
    //console.log(this.days.value);

    // Datum ermitteln
    let date = new Date()
    let currentMonth = date.getMonth()+1;
    let day = date.getDate();
    let dayOfTheWeek = date.getDay()

    let daysOfTheMonth = this.getTageProMonat(currentMonth);
    console.log(daysOfTheMonth);
    const arr = Object.values(this.days.value);
    do {
      //console.log("tag: "+ day);
      date.setDate(day)
      // console.log(date);
      // wenn Tag == trainingstag -> erstelle training
      if(arr[date.getDay()-1]) {
        console.log("erstelle" + date.getDate());
        this.trainfactory(date)
      }
      day++;

    }
    while (day < daysOfTheMonth)


  }

    getDay(tag: string) {

      let date = new Date()
      let day = date.getDay()
      let tag2 = parseInt(tag)
      let dif = 0;
      for(day; day<=5;day++,dif++) {
        if(tag2 == day) {
          date.setDate(date.getDate()+dif)
          this.trainfactory(date)
        }
      }
    }

  async write(training: Training) {
    await this.bs.addTraining(training).toPromise().then(res => {
      if(res) {
        this.dialog.openMessageDialog(res.text)
      }
    }).catch( error => {
      console.log(error);
      this.dialog.openMessageDialog(JSON.stringify(error))
    })
  }

  async write2(training: Training2) {
    await this.bs.addTraining2(training).toPromise().then( res =>
      //this.router.navigate(['/home'])
      window.alert('ok')
    )
  }

  test() {
    //this.dialog.openMessageDialog("test")
    // Swal.fire('Hello world!');
  }

}
