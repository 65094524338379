import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'bm-abwesenheit',
  templateUrl: './abwesenheit.component.html',
  styleUrls: ['./abwesenheit.component.css']
})
export class AbwesenheitComponent implements OnInit {
  range = new FormGroup({
    start: new FormControl(),
    end: new FormControl()
  });

  constructor(@Inject(MAT_DIALOG_DATA) public data: {message: string},
  public dialogRef: MatDialogRef<AbwesenheitComponent>) { }

  ngOnInit(): void {
  }

  save(e: any) {
    console.log(e.target);

  }

  closeDialog() {
    this.dialogRef.close(false);
  }

}
