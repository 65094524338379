<div *ngIf="training$ | async as training">
    <mat-grid-list cols="2" rowHeight="2:1">
        <mat-grid-tile>
            <img src="../../../assets/icons/{{training.kategorie}}.svg">
        </mat-grid-tile>
        <mat-grid-tile>
            <div class="count">
                <ng-container *ngIf="countSpieler && dabei && raus">
                    <div class="meldungen">{{countSpieler}}
                        <mat-icon aria-hidden="false" aria-label="Example home icon">thumbs_up_down</mat-icon>
                    </div>
                    <div class="dabei">{{dabei}}
                        <mat-icon aria-hidden="false" aria-label="Example home icon">thumb_up</mat-icon>
                    </div>
                    <div class="raus">{{raus}}
                        <mat-icon aria-hidden="false" aria-label="Example home icon">thumb_down</mat-icon>
                    </div>
                </ng-container>
            </div>
        </mat-grid-tile>
    </mat-grid-list>




    <div *ngIf="training.pflicht != 0">Pflichttermin</div>
    <h1> Training am {{ training.datum | date:'shortDate'}} um {{training.zeit}} Uhr</h1>

    <p *ngIf="training.zusatz">
        {{training.zusatz}}
    </p>
<!-- {{training | json}} -->
  <bm-kalender-button [training]="training" [typ]="typ"></bm-kalender-button>

    <ng-container *ngIf="adminmode">

        <mat-accordion *ngIf="adminmode">
            <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">
              <button mat-flat-button color="primary" (click)="openEdit()">Training editieren</button>

                <button mat-flat-button color="warn" (click)="openDialog()">Training löschen</button>
                <mat-expansion-panel-header>
                    <mat-panel-description>
                        Training verwalten
                    </mat-panel-description>
                </mat-expansion-panel-header>

                <bm-spieler-meldung *ngIf="alleSpieler" (newItemEvent)="addItem($event)" [isSpiel]="isSpiel" [id]="id" [spieler]="alleSpieler" [adminmode]="adminmode"></bm-spieler-meldung>

            </mat-expansion-panel>
        </mat-accordion>
    </ng-container>


    <div class="ui two buttons bt" *ngIf="showAnmeldebuttons && !zeitAbgelaufen">
        <button class="ui button" (click)="melde(2)">Absagen</button>
        <div class="or"></div>
        <button class="ui positive button" (click)="melde(1)">Zusagen</button>
    </div>

    <div class="class='content'" *ngIf="zeitAbgelaufen">
      <div class="alert alert-warning alert-white rounded">
        <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
        <div class="icon"><i class="bi bi-bell"></i></div>
        <strong>Meldund nicht mehr möglich!</strong> Frist ist abgelaufen.
    </div>
    </div>

    <!--    <div class="spieler" fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="flex-start">
        <bm-spieler-spieltag class="item" fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%" *ngFor="let s of training.spieler" [spieler]="s"></bm-spieler-spieltag>
    </div> -->

    <bm-spieler-training *ngFor="let s of training.spieler" [spieler]="s" [id]="id" [event]=2 (neuladen)="neuladen($event)" (parent)="parent($event)"></bm-spieler-training>
</div>



<!-- <div class='content'>
  <div class="alert alert-success alert-white rounded">
      <button type="button" class="close" data-dismiss="alert" aria-hidden="true" (click)="test()">×</button>
      <div class="icon"><i class="fa fa-check"></i></div>
      <strong>Success!</strong> Changes has been saved successfully!
  </div>

  </div> -->
