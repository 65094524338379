<table mat-table [dataSource]="kontoauszug" class="mat-elevation-z8">

    <!--- Note that these columns can be defined in any order.
        The actual rendered columns are set as a property on the row definition" -->

    <!-- Position Column -->
    <ng-container matColumnDef="id">
        <th mat-header-cell *matHeaderCellDef> ID </th>
        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="datum">
        <th mat-header-cell *matHeaderCellDef> Datum </th>
        <td mat-cell *matCellDef="let element"> {{element.datum| date: "shortDate"}} </td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="erlauterung">
        <th mat-header-cell *matHeaderCellDef> Buchung </th>
        <td mat-cell *matCellDef="let element"> {{element.erlaeuterung}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="betrag">
        <th mat-header-cell *matHeaderCellDef> Betrag </th>
        <td mat-cell *matCellDef="let element"> {{element.betrag}} </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>
