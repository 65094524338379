

<mat-card>
  <mat-card-content>

    <form [formGroup]="trainData">

      <div class="box">
      <mat-form-field appearance="fill">
        <mat-label>Spieler</mat-label>
        <mat-select name="spieler" formControlName="spieler">
          <mat-option *ngFor="let val of spieler" [value]="val.idspieler">
            {{val.name +", " +val.vorname}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div>


      <section class="example-section">
        <mat-radio-group formControlName="cat">

          <mat-radio-button class="example-margin" value="biking">
            <mat-icon>directions_bike</mat-icon> Biken
          </mat-radio-button>

          <mat-radio-button class="example-margin" value="ebiking">
            <mat-icon>electric_bike</mat-icon> E-Biken
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="jogging">
            <mat-icon>directions_run</mat-icon> Joggen
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="inliner">
            <mat-icon>roller_skating</mat-icon> Inliner fahren
          </mat-radio-button>
          <mat-radio-button class="example-margin" value="swimming">
            <mat-icon>pool</mat-icon> Schwimmen
          </mat-radio-button>
        </mat-radio-group>
      </section>

    </div>
    <div class="box">
      <mat-form-field class="example-full-width">
        <mat-label>Distanz</mat-label>
        <input type="number" formControlName="distance" matInput placeholder="Distanz in KM" value="distanz">
      </mat-form-field>
    </div>
    <div>
      <mat-form-field class="example-full-width">
        <mat-label>Datum</mat-label>
        <input type="date" formControlName="date" matInput placeholder="Distanz in Metern" value="distanz">
      </mat-form-field>
    </div>
      <div>
        <button mat-raised-button color="primary" (click)="save()">speichern</button>

      </div>

    </form>
    </mat-card-content>
  </mat-card>


