import { Component, OnInit } from '@angular/core';
import { Spieler } from '../../shared/spieler';
import { BookStoreService } from '../../shared/book-store.service';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Buchung2 } from '../../shared/buchung';
import { DialogService } from '../../service/dialog.service';
import { Buchung } from 'src/app/admin/shared/spieler-anzahl-spiel';

@Component({
  selector: 'bm-buchen',
  templateUrl: './buchen.component.html',
  styleUrls: ['./buchen.component.css']
})
export class BuchenComponent implements OnInit {
  spieler: Spieler[];

/*   form = new FormGroup({
    name: new FormControl(''),
    betrag: new FormControl(''),
    betreff: new FormControl('')
  }); */



  constructor(
    private bs: BookStoreService,
    private fb: FormBuilder,
    private dialog: DialogService
    ) { }

  form= this.fb.group({
    spielerid: ['', Validators.required],
    betrag: ['', Validators.required],
    zweck: ['', Validators.required]
  })

  ngOnInit(): void {
    this.bs.getAllSpieler(false, true).subscribe(res => this.spieler = res);
  }

  buchen(): void {
    const values = this.form.value;
    const buchung: Buchung = {
      ...values,
      team: 3
    }

    this.dialog.openConfirmDialog('JETZT BUCHEN?')
    .afterClosed().subscribe(res => {
      if(res) {
        //console.log("okay");
        //console.log(buchung);
        this.bs.belasteSpieler(buchung).toPromise().then(( res => {
          if(res) {
            this.dialog.openMessageDialog("okay");
          }
          this.form.reset();
        }))
      } else {
        this.dialog.openMessageDialog("Fehler");
      }
    })
  }

}
