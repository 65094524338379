<div class="ui middle aligned selection divided list">
    <ng-container *ngIf="training$ | async as training; else loading">

        <bm-training-item *ngFor="let t of training" [training]="t" [routerLink]="t.id"></bm-training-item>

        <p *ngIf="!training.length">Kein Training gefunden.</p>
    </ng-container>

    <ng-template #loading>
        <div class="ui active dimmer">
            <div class="ui large text loader">Daten werden geladen...</div>
        </div>
    </ng-template>
</div>