<h1>Schiedsrichter</h1>

<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutAlign="space-between">



    <ng-template #loading>
        <div class="ui active dimmer">
            <div class="ui large text loader">Daten werden geladen...</div>
        </div>
    </ng-template>
    <ng-container *ngFor="let s of schiris">
        <bm-schiedsrichter-list-item fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%" [spielleiter]="s" [routerLink]="['/schiedsrichter/', s.idspielleiter]"></bm-schiedsrichter-list-item>
    </ng-container>
</div>
