import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from  '@angular/forms';
import { Router } from  '@angular/router';
import { AuthService } from  '../shared/auth.service';
import { Spieler } from '../shared/spieler';
import { Login } from '../shared/login';
import { DialogService } from '../service/dialog.service';

@Component({
  selector: 'bm-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  authForm: FormGroup;
  isSubmitted  =  false;
  spieler: Spieler;

  constructor(
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private dialog: DialogService
  ) { }


  ngOnInit(): void {
    // if(localStorage.getItem('auth') && localStorage.getItem('team')) {
    //   this.router.navigateByUrl('/home');
    // }

    this.authForm = this.formBuilder.group({
      email: ['', Validators.required],
      password: ['', Validators.required]
    });
  }
  get formControls() { return this.authForm.controls; }


  login() {
    let mail = this.authForm.value.email.trim();
    console.log(mail+":");
    const login: Login = {
      email: mail,
      password: this.authForm.value.password
    }

    if(this.authForm.valid) {
      // const login: Login = {
      //   ...this.authForm.value
      // }
      this.authService.loginNew(login).toPromise().then(res => {
        if(res){
          this.spieler = res;
          console.log("login :"+ res);

          if(login.email == this.spieler.email) {
            localStorage.setItem('auth', this.spieler.idspieler.toString());
            if(this.spieler.admin == 1) {
              localStorage.setItem('admin', 'true');
            }
            const team = this.spieler.team.toString();
            if(team)  {
              localStorage.setItem('team', team );
            } else {
              localStorage.setItem('team', '3' );
            }
            localStorage.setItem('vl', '3')

            this.router.navigateByUrl('home');
          }
          else this.dialog.openMessageDialog("Fehler")
        } else this.dialog.openMessageDialog("Fehler")
      })
    }
  }


}


