import { Component, OnInit} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { BookStoreService } from '../shared/book-store.service';
import { Spieltag } from '../shared/spieltag';
import { Training } from '../shared/training';
import { Spieler } from '../shared/spieler';


import { Termin } from '../shared/termin';
import { trigger, transition, style, animate, query, stagger, state } from '@angular/animations';
// import { TrainData } from '../shared/train-data';
// import { TraindataExtendet } from '../shared/trainData';

declare var require: any
const FileSaver = require('file-saver');


@Component({
  selector: 'bm-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  animations: [
    trigger('move',[
      state('1',style({opacity:1})),
      state('2',style({opacity:1})),
      transition('false => true',[
      animate('1000ms'),
    ])]),

    trigger('test',[
      state('250ms',style({opacity:1})),

      state('500ms',style({opacity:0.5})),
      transition(':enter',[
        style({
        opacity: 0 }), animate('300ms', style({ opacity: 1 }))
      ])
    ]),

    trigger('listAnimation', [
      transition('* => *', [
        query(':enter', [
          style({ opacity: 0 }),
          stagger(100, [
            animate('2s', style({ opacity: 1 }))
          ])
        ], { optional: true })
      ])
    ]),
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('1s ease-out',
                    style({ height: 150, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 300, opacity: 1 }),
            animate('1s ease-in',
                    style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class HomeComponent implements OnInit {
  position:string = 'left'/*  */
  leftPosition:string = '300px'
  animationTime:string = '1000ms'

  spieltag$: Observable<Spieltag[]>;
  training$: Observable<Training[]>;
  spieler$: Observable<Spieler[]>;
  termine$: Observable<Termin[]>;
  datum = new Date();
  td: Spieler[];
  // rangliste: Spieler[] = [];


  eventText = '';
  direction = "";
  products: any = [];

  constructor(
    private bs: BookStoreService,
    )
    { }


  ngOnInit(): void {
    //this.loadData()
    // this.kontostand$ = this.bs.getKontostand(parseInt(localStorage.getItem('auth')),parseInt(localStorage.getItem('team')))
    //this.termine$ = this.bs.getTermine();

    this.spieltag$ = this.bs.getCurSpiele();
    this.training$ = this.bs.getCurTraining();
    this.termine$ =  this.bs.getTermineA();

    let geburtstag = true;
    this.spieler$ = this.bs.getAllSpieler(geburtstag, false);

  //   this.bs.getAllTraindata(this.bs.getCurSaison()).subscribe(res => {
  //     console.log(res);
  //     this.td = res

  //     for(let item of this.td) {
  //       let listItem: Spieler = {
  //         idspieler: item.idspieler,
  //         name: item.name,
  //         vorname: item.vorname,
  //         email: item.email,
  //         bild: item.bild,
  //         geburtstag: item.geburtstag,
  //         summe: item.summe
  //       }
  //       this.rangliste.push(listItem)
  //     }
  //   })

  }

  seasons$ = this.bs.getSeasons().subscribe({
    next: value => console.log('Observable emitted the next value: ' + value),
    error: err => console.error('Observable emitted an error: ' + err),
    complete: () => console.log('Observable emitted the complete notification')
})



}
