import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BookStoreService } from '../../shared/book-store.service';
import { Spielleiter } from '../../shared/spielleiter';


@Component({
  selector: 'bm-schiedsrichter-liste',
  templateUrl: './schiedsrichter-liste.component.html',
  styleUrls: ['./schiedsrichter-liste.component.css']
})
export class SchiedsrichterListeComponent implements OnInit {
  schiris$: Observable<Spielleiter[]>
  schiris: Spielleiter[];

  name: string[] = ["wrd","ewf"];

  constructor(
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {

    this.schiris$ = this.bs.getAllSchiri();
    this.bs.getSchiri().subscribe(
    {
      next: data => {
          this.schiris = data;
          console.log(this.schiris);
      },
      error: error => {
          //this.errorMessage = error.message;
          console.error('There was an error!', error);
      }
    })
  }

}
