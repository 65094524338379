import { BrowserModule } from '@angular/platform-browser';
import { NgModule, LOCALE_ID, Inject } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { registerLocaleData, HashLocationStrategy, LocationStrategy } from '@angular/common';
import localeDe from '@angular/common/locales/de'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { TokenInterceptor } from './shared/token.interceptor';
import { IconMailComponent } from './src/assets/icons/icon-mail/icon-mail.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { SpielerItemComponent } from './spieler/spieler-item/spieler-item.component';
import { SpielerListeComponent } from './spieler/spieler-liste/spieler-liste.component';
import { SpielerDetailsComponent } from './spieler/spieler-details/spieler-details.component';
import { SpieleComponent } from './spielbetrieb/spiele/spiele.component';
import { TrainingComponent } from './spielbetrieb/training/training.component';
import { SpieltagItemComponent } from './spielbetrieb/spieltag-item/spieltag-item.component';
import { TrainingItemComponent } from './spielbetrieb/training-item/training-item.component';
import { TrainingDetailsComponent } from './spielbetrieb/training-details/training-details.component';
import { SpieltagDetailsComponent } from './spielbetrieb/spieltag-details/spieltag-details.component';
import { LoginComponent } from './login/login.component';
import { AuthComponent } from './auth/auth.component';
import { FormsModule, ReactiveFormsModule} from '@angular/forms';
import { VereineListeComponent } from './vereine-liste/vereine-liste.component';
import { VereineListeItemComponent } from './vereine-liste-item/vereine-liste-item.component';
import { CreateTrainingComponent } from './spielbetrieb/create-training/create-training.component';
import { CreateSpieltagComponent } from './spielbetrieb/create-spieltag/create-spieltag.component';
import { MenuComponent } from './shared/menu/menu.component';
import { MatGridListModule} from '@angular/material/grid-list';
import { MatSliderModule } from '@angular/material/slider';
import { MatRadioModule} from '@angular/material/radio';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { MatButtonModule} from '@angular/material/button';
import { MatSelectModule} from '@angular/material/select';
import { MatIconModule} from '@angular/material/icon';
import { MatMenuModule} from '@angular/material/menu';
import { MatFormFieldModule} from '@angular/material/form-field';
import { MatInputModule} from '@angular/material/input';
import { MatDialogModule} from '@angular/material/dialog';
import { SettingsComponent } from './settings/settings.component';
import { CreateClubComponent } from './create-club/create-club.component';
import { MatTableModule} from '@angular/material/table';
import { MatSlideToggleModule} from '@angular/material/slide-toggle';
import { VereinDetailsComponent } from './spielbetrieb/verein-details/verein-details.component';
import { EditVereinComponent } from './spielbetrieb/edit-verein/edit-verein.component';
import { AddSpielDatenComponent } from './spielbetrieb/add-spiel-daten/add-spiel-daten.component';

// import { OrtComponent } from './spielbetrieb/ort/ort.component';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTabsModule} from '@angular/material/tabs';
import {MatListModule} from '@angular/material/list';
import {MatChipsModule} from '@angular/material/chips';
import { MatDatepickerModule} from '@angular/material/datepicker';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import {MatExpansionModule} from '@angular/material/expansion';
import { StarComponent } from './src/assets/star/star.component';

import { FlexLayoutModule } from '@angular/flex-layout';

import {MatCardModule} from '@angular/material/card';
import { FooterComponent } from './shared/footer/footer.component';
import { RegisterComponent } from './register/register.component';
import { SpielerMeldungComponent } from './spielbetrieb/spieler-meldung/spieler-meldung.component';
import { SpielerTrainingComponent } from './spieler-training/spieler-training.component';
import { ProfilComponent } from './profil/profil.component';
import { TableComponent } from './profil/table/table.component';
import { BuchenComponent } from './kasse/buchen/buchen.component';
import { MatConfirmDialogComponent } from './dialog/mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialogComponent } from './dialog/mat-dialog/mat-dialog.component';
import { AuswahlMannschaftComponent } from './template/auswahl-mannschaft/auswahl-mannschaft.component';
import { PlatzdienstComponent } from './spielbetrieb/platzdienst/platzdienst.component';
import { StrafenComponent } from './spielbetrieb/strafen/strafen.component';
import { KontostandListeComponent } from './kasse/kontostand-liste/kontostand-liste.component';

import { HelpComponent } from './help/help.component';
import { TerminItemComponent } from './item/termin-item/termin-item.component';
import { TermindetailsComponent } from './termindetails/termindetails.component';
import { SaisonListeComponent } from './saison-liste/saison-liste.component';
import { StatistikComponent } from './statistik/statistik/statistik.component';
import { SpielerEditComponent } from './spieler/spieler-edit/spieler-edit.component';
import { UpdateTrainingComponent } from './dialog/update-training/update-training.component';
import { FormComponent } from './shared/form/form.component';
import { MultiSpielerItemComponent } from './shared/multi-spieler-item/multi-spieler-item.component';
import { AbwesenheitComponent } from './dialog/abwesenheit/abwesenheit.component';
import { MatNativeDateModule } from '@angular/material/core';
import { NewPasswordComponent } from './dialog/new-password/new-password.component';
import { KalenderButtonComponent } from './shared/kalender-button/kalender-button.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    IconMailComponent,
    SpielerItemComponent,
    SpielerListeComponent,
    SpielerDetailsComponent,
    SpieleComponent,
    TrainingComponent,
    SpieltagItemComponent,
    TrainingItemComponent,
    TrainingDetailsComponent,
    SpieltagDetailsComponent,
    LoginComponent,
    AuthComponent,
    VereineListeComponent,
    VereineListeItemComponent,
    CreateTrainingComponent,
    CreateSpieltagComponent,
    MenuComponent,
    SettingsComponent,
    CreateClubComponent,
    VereinDetailsComponent,
    EditVereinComponent,
    AddSpielDatenComponent,
    // OrtComponent,
    // SchiedsrichterListeComponent,
    // SchiedsrichterListItemComponent,
    // SchiedsrichterDetailsComponent,
    StarComponent,
    FooterComponent,
    RegisterComponent,
    SpielerMeldungComponent,
    SpielerTrainingComponent,
    ProfilComponent,
    TableComponent,
    BuchenComponent,
    MatConfirmDialogComponent,
    MatDialogComponent,
    AuswahlMannschaftComponent,
    PlatzdienstComponent,
    StrafenComponent,
    KontostandListeComponent,
    HelpComponent,
    TerminItemComponent,
    TermindetailsComponent,
    SaisonListeComponent,
    StatistikComponent,
    SpielerEditComponent,
    UpdateTrainingComponent,
    FormComponent,
    MultiSpielerItemComponent,
    AbwesenheitComponent,
    NewPasswordComponent,
    KalenderButtonComponent
  ],
  imports: [
    HttpClientModule,BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    FlexLayoutModule, MatListModule,
    AppRoutingModule,
    MatTabsModule, MatDialogModule,
    MatGridListModule, MatDialogModule, MatTableModule, MatRadioModule, MatToolbarModule,
    MatSliderModule, MatCheckboxModule, MatRadioModule, MatInputModule, MatFormFieldModule, MatSelectModule, MatCardModule, MatButtonModule,
    MatIconModule, MatMenuModule, MatSlideToggleModule, MatDialogModule, MatExpansionModule,
    MatChipsModule, MatDatepickerModule,MatNativeDateModule,
    MatSliderModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
  ],
  providers: [

    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: LOCALE_ID, useValue: 'de' },
    { provide: LocationStrategy, useClass: HashLocationStrategy}
  ],
  bootstrap: [AppComponent],
  entryComponents: [MatConfirmDialogComponent]
})
export class AppModule {
  constructor(@Inject(LOCALE_ID) locale: string) {
    registerLocaleData(localeDe);
    // console.log('DEBUG – Current Locale:', locale);
  }
}


