<mat-card class="example-card" [ngClass]="{'heute': spieltag.datum === heute, 'ah': spieltag.team === 4 }">
    <mat-card-header>
        <div mat-card-avatar>

  <ng-container *ngIf="spieltag.logo; else elseBlock">
                <img class="ui mini image looper" src="../../../assets/teams/{{spieltag.logo}}">
            </ng-container>

        </div>
        <mat-card-title class="">
            <p *ngIf="spieltag.team < 4" class="team">{{spieltag.typ + " von der " + spieltag.team}}. Mannschaft gegen:</p>
            <p *ngIf="spieltag.team == 4" class="team">{{spieltag.typ + " von der Alten Herren"}}  gegen:</p>
             {{spieltag.gegner | uppercase}}</mat-card-title>
        <mat-card-subtitle>Am: {{spieltag.datum}} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>

    </mat-card-content>

</mat-card>
<ng-template #elseBlock><img class="ui tiny image" src="../../../assets/teams/{{spieltag.logo}}"></ng-template>
