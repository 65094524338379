<div>
  <div class="content-container">
      <mat-icon id="close-icon" (click)="closeDialog(false)">close</mat-icon>
      <span class="content-span full-width"></span>
  </div>


  <form class="example-form" [formGroup]="trainForm">
    <h2>Training</h2>

    <table class="example-full-width" cellspacing="0"><tr>
      <td>
        <label for="datum">Datum: </label>
        <input id="datum" type="date" formControlName="datum">
      </td>

      <td>
        <label for="zeit">Zeit: </label>
        <input id="zeit" type="time" formControlName="zeit">
      </td>

    </tr></table>


    <div>
      <mat-slide-toggle  name="pflicht" formControlName="pflicht">Pflicht</mat-slide-toggle>

    </div>



    <mat-form-field appearance="fill">
      <mat-label>Kategorie</mat-label>
      <mat-select formControlName="kategorie" name="kategorie">
        <mat-option>None</mat-option>
        <mat-option *ngFor="let k of kat" [value]="k">
          {{k}}
        </mat-option>
      </mat-select>
    </mat-form-field>

<div>
  <label for="zusatz">Kommentar: </label>
  <textarea id="zusatz" type="text" formControlName="zusatz" rows="10" cols="70"></textarea>
</div>



  </form>

  <button mat-flat-button color="warn" id="no-button" [mat-dialog-close]="false">ABBRECHEN</button>
  <button mat-flat-button color="primary" (click)="save()">SPEICHERN</button>

</div>
