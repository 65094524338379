<mat-card>
    <mat-card-content>
        <h2 class="example-h2">Spielplan anzeigen</h2>

        <section class="example-section">
            <label class="example-margin">Saison</label>
            <mat-radio-group [(ngModel)]="saisonPosition">
                <mat-radio-button class="example-margin" value="0">17/18</mat-radio-button>
                <mat-radio-button class="example-margin" value="1">18/19</mat-radio-button>
                <mat-radio-button class="example-margin" value="2">19/20</mat-radio-button>
                <mat-radio-button class="example-margin" value="3">20/21</mat-radio-button>
                <mat-radio-button class="example-margin" value="4">21/22</mat-radio-button>
                <mat-radio-button class="example-margin" value="5">22/23</mat-radio-button>
                <mat-radio-button class="example-margin" value="6">23/24</mat-radio-button>
                <mat-radio-button class="example-margin" value="7">23/24</mat-radio-button>


            </mat-radio-group>
        </section>
        <section class="example-section">
            <label class="example-margin">Team</label>
            <mat-radio-group [(ngModel)]="teamPosition">
                <mat-radio-button class="example-margin" value="1">Erste</mat-radio-button>
                <mat-radio-button class="example-margin" value="2">Zweite</mat-radio-button>
                <mat-radio-button class="example-margin" value="3">Dritte</mat-radio-button>
                <mat-radio-button class="example-margin" value="4">Alte Herren</mat-radio-button>
            </mat-radio-group>
        </section>
        <section>

            <div class="example-button-row">
                <button mat-raised-button color="accent" (click)="change()">Aktualisieren</button>
            </div>
        </section>
    </mat-card-content>
</mat-card>
<div class="ui middle aligned selection divided list">
    <ng-container *ngIf="spieltag$ | async as spiele; else loading">

        <bm-spieltag-item class="items" *ngFor="let s of spiele" [spieltag]="s" [routerLink]="s.idspieltag"></bm-spieltag-item>

        <p *ngIf="!spiele.length">Keine Spiele gefunden.</p>
    </ng-container>

    <ng-template #loading>
        <div class="ui active dimmer">
            <div class="ui large text loader">Daten werden geladen...</div>
        </div>
    </ng-template>

</div>
