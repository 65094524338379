import { Spielort } from "../shared/spielort";

export class Verein {
  idVerein: number;
  name: string;
  logo: string;
  idspielort: number;
  ort: Spielort;
  mannschaften: number;
  ah: number;

  constructor(idVerein: number, name:string, logo:string, idspielort:number, ort:Spielort, mannschaften:number, ah:number) {
    this.idVerein = idVerein;
    this.name = name;
    this.logo = logo;
    this.idspielort = idspielort;
    this.ort = ort;
    this.mannschaften = mannschaften;
    this.ah = ah
  }
}
