<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="10px" fxLayoutAlign="flex-start">




    <ng-container *ngIf="spieler as spieler; else loading">

        <bm-spieler-item fxFlex="0 1 calc(33.3% - 10px)" fxFlex.lt-md="0 1 calc(50% - 10px)" fxFlex.lt-sm="100%" *ngFor="let s of spieler" [spieler]="s" [site]="'list'" [routerLink]="s.idspieler"></bm-spieler-item>

        <p *ngIf="!spieler.length">Es wurden noch keine Spieler eingetragen.</p>
    </ng-container>

    <ng-template #loading>
        <div class="ui active dimmer">
            <div class="ui large text loader">Daten werden geladen...</div>
        </div>
    </ng-template>
</div>
