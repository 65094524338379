import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class CanNavigateToAdminGuard implements CanActivate {

  canActivate(): boolean {
    if(localStorage.getItem('admin')=== 'true') {
      return true
    }
    return false
  }
}
