<div *ngIf="schiri$ | async as schiri">

<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar class="example-header-image"></div>
    <mat-card-title>{{schiri.name}}</mat-card-title>
    <mat-card-subtitle>Schiedsrichter</mat-card-subtitle>
  </mat-card-header>
  <img mat-card-image *ngIf="schiri.bild" src="../../../assets/schiris/{{schiri.bild}}" alt="bild">
  <mat-card-content>
    <p>
      {{schiri.kommentare}}
    </p>
    <h3 *ngIf="schiri.wertung != 0.00">Wertung: {{schiri.wertung}}</h3>
  </mat-card-content>
  <mat-card-actions>
  <!--
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button> -->
  </mat-card-actions>
</mat-card>
</div>
