<form [formGroup]="form">

  <mat-form-field appearance="fill">
    <mat-label>Jahr</mat-label>
    <mat-select [formControl]="saisonControl" name="saison">
      <!-- <mat-option>None</mat-option> -->
      <mat-option *ngFor="let saison of saisons" [value]="saison.value">
        {{saison.jahre}} </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Monat</mat-label>
    <mat-select [formControl]="monatControl" name="monat">
      <mat-option [value]=0>Alle</mat-option>
      <mat-option *ngFor="let monat of monate" [value]="monat.key">
        {{monat.value}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Woche</mat-label>
    <mat-select [formControl]="wocheControl" name="kw">
      <mat-option [value]="0">Alle</mat-option>
      <mat-option *ngFor="let kw of wochenArray" [value]="kw">
        {{kw}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Pflicht</mat-label>
    <mat-select [formControl]="pflichtControl" name="pflicht">
      <!-- <mat-option>None</mat-option> -->
      <mat-option [value]=2>egal</mat-option>
      <mat-option [value]=1>ja</mat-option>
      <mat-option [value]=0>nur freiwillig</mat-option>
    </mat-select>
  </mat-form-field>

  <button mat-flat-button color="primary" (click)="choose()">Wählen</button>

</form>
