<mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar>
            <ng-container *ngIf="spieler.bild; else elseBlock">
                <img class="ui mini image" src="../../../assets/spielerFotos/{{spieler.bild}}"> </ng-container>
        </div>
        <mat-card-title>{{ spieler.name + ', ' + spieler.vorname }}</mat-card-title>
        <mat-card-subtitle *ngIf="!geburtstag">E-Mail: {{spieler.email}}</mat-card-subtitle>
        <mat-card-subtitle *ngIf="geburtstag">geb: {{spieler.geburtstag | date: 'shortDate'}}</mat-card-subtitle>
        <ng-container *ngIf="site != 'home'">
            <div *ngIf="spieler.aktiv == '1'; else passiv">
                <mat-icon>check_circle</mat-icon>
            </div>

        </ng-container>

    </mat-card-header>
    <img mat-card-image>
    <!-- <mat-card-content></mat-card-content>
    <button mat-button>LIKE</button>
    <button mat-button>SHARE</button>
  </mat-card-actions>-->
</mat-card>


<ng-template #passiv>
    <mat-icon>highlight_off</mat-icon>
</ng-template>



<ng-template #elseBlock><img class="ui tiny image " src="../../../assets/spielerFotos/ohne.jpg "></ng-template>
