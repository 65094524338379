import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, Input, OnInit } from '@angular/core';
import { Training } from 'src/app/shared/training';

@Component({
  selector: 'bm-training-item',
  templateUrl: './training-item.component.html',
  styleUrls: ['./training-item.component.css'],
  animations: [
    trigger('move',[
      state('0',style({opacity:1})),
      state('1',style({opacity:1})),
      state('2',style({opacity:1})),
      transition('* <=> *',[
      animate('1000ms'),
    ])]),
  ]
})
export class TrainingItemComponent implements OnInit {
  @Input() training: Training;
  @Input() i: number;
  date = new Date();
  heute: boolean;

  constructor() { }

  ngOnInit(): void {
    //console.log(this.training.datum)
    let datum = new Date(this.training.datum);
    //console.log(this.date.getFullYear() + ':' + datum.getFullYear() )

    let d1: string;
    let d2: string;

    d1 = this.date.getDate().toString();
    d2 = datum.getDate().toString();
    if(d1 === d2) {
      this.heute = true;
    }
  }

}
