import { Injectable } from '@angular/core';
import { MatDialog} from '@angular/material/dialog'
import { MatConfirmDialogComponent } from '../dialog/mat-confirm-dialog/mat-confirm-dialog.component';
import { MatDialogComponent } from '../dialog/mat-dialog/mat-dialog.component';
import { UpdateTrainingComponent } from '../dialog/update-training/update-training.component';
import { Training } from '../shared/training';
import { AbwesenheitComponent } from '../dialog/abwesenheit/abwesenheit.component';
import { Spieler } from '../shared/spieler';
import { SpielerEditComponent } from '../spieler/spieler-edit/spieler-edit.component';
import { NewPasswordComponent } from '../dialog/new-password/new-password.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(
    private dialog: MatDialog
  ) { }

  openConfirmDialog(msg: any) {
    return this.dialog.open(MatConfirmDialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      position: { top: "10px"},
      data:{
        message : msg
      }
    });
  }

  openMessageDialog(msg: any) {
    this.dialog.open(MatDialogComponent, {
      width: '300px',
      disableClose: true,
      id: 'okay-component',
      position: { top: "10px"},
      data:{
        message : msg
      }
    })
  }

  openTraining(training: Training) {
    this.dialog.open(UpdateTrainingComponent, {
      width: '50rem',
      height: '50rem',
      disableClose: true,
      position: { top: "10px"},
      data:training
    })
  }

  newPassword(idspieler: number) {
    this.dialog.open(NewPasswordComponent, {
      width: '50rem',
      height: '30rem',
      id: 'modal-component',
      disableClose: true,
      position: { top: "10px"},
      data:idspieler
    })
  }

  openEditSpieler(spieler: Spieler) {
    this.dialog.open(SpielerEditComponent, {
      width: '50rem',
      height: '50rem',
      disableClose: true,
      position: { top: "10px"},
      data:spieler
    })
  }

  openAbwesenheit(msg: any) {
    this.dialog.open(AbwesenheitComponent, {
      width: '50rem',
      height: '50rem',
      disableClose: true,
      position: { top: "10px"},
      data:{
        message : msg
      }
    })
  }
}
