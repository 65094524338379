<div style="text-align:center">
  <h1>
    Welcome Admin!
  </h1>

  <p>
    <button (click)="logout()">
      Logout
    </button>
  </p>
</div>

  <ol>
    <li>neuer Spielort</li>
     <li>neues Spiel</li>
     <li>neuer Schiri</li>
     <li>neuer Verein</li>
  </ol>




