import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { async } from 'rxjs/internal/scheduler/async';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Meldung } from 'src/app/shared/meldung';
import { Spieler } from 'src/app/shared/spieler';

@Component({
  selector: 'bm-spieler-meldung',
  templateUrl: './spieler-meldung.component.html',
  styleUrls: ['./spieler-meldung.component.css']
})
export class SpielerMeldungComponent implements OnInit {
  @Input() spieler: Spieler[];
  @Input() isSpiel: boolean;
  @Input() id: number;
  @Output() newItemEvent = new EventEmitter<string>();

  selected: number

  constructor(
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {
    console.log(this.isSpiel);

  }

  melde(dabei: number) {
    let table = "";
    console.log(dabei);
    if(!this.isSpiel) {
      table = "vt_training_spieler"
    }
    const meldung: Meldung = {
      table: table,
      idspieler: this.selected,
      idevent: this.id,
      status:  dabei,
      zeitstempel: null
    }
    console.log(meldung);
    this.set(meldung)
  }

  async set(m: Meldung) {
    await this.bs.melde(m).toPromise()
    this.newItemEvent.emit("neuLaden");
  }

}
