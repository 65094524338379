import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Zusatzinfos } from '../../shared/zusatzinfos';
import { Spieltag } from 'src/app/shared/spieltag';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spielort } from 'src/app/shared/spielort';
import Swal from 'sweetalert2';

@Component({
  selector: 'bm-add-spiel-daten',
  templateUrl: './add-spiel-daten.component.html',
  styleUrls: ['./add-spiel-daten.component.css']
})
export class AddSpielDatenComponent implements OnInit {
  addForm: FormGroup;

  spielorte: Spielort[];

  @Input() spieltag: Spieltag;
  @Output() submitInfos = new EventEmitter<Zusatzinfos>();

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) { }

  ngOnInit(): void {
    //console.log(this.spieltag);
    this.bs.getAllOrte(0).subscribe(res => {this.spielorte = res })
    this.initForm();
  }

  private initForm() {
    if (this.addForm) { return; }
    this.addForm = this.fb.group({
      idspielort: [''],
      treffOrt: [this.spieltag.treffpunktOrt],
      treffZeit: [this.spieltag.treffpunktZeit],
      zusatz: [this.spieltag.kommentar],
      ergebnis: [this.spieltag.ergebnis]
    })
  }

  async submitForm() {
    const params = this.route.snapshot.paramMap;
    const id = params.get('id');
    const formValue = this.addForm.value;

    const spieltag: Spieltag = {
      gegner: this.spieltag.gegner ,
      ...formValue,
      idspieltag: this.spieltag.idspieltag
    };
    //console.log(spieltag);
    await this.bs.updateSpieltag(spieltag).toPromise().then(async res => {
      if(res) {
        Swal.fire({
          position: 'top-end',
          icon: 'success',
          title: 'Erfolgreich gespeichert',
          showConfirmButton: false,
          timer: 1500
        })
      }
    })
    //this.submitInfos.emit(newInfos);
  }
}
