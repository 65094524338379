<div class="container">

    <h2>

        Strafen</h2>
    <div class="paragraph">§1 Falschmeldung</div>
    <div class="text">
        Eine Falschmeldung beschreibt den Vorgang sich versehentlich oder wissentlich falsch in der App zu einer Veranstaltung gemeldet zu haben. Diese kann bis zu der Ablauf der gegebenen Frist bei dem Trainer widerrufen werden.
    </div>
    <div class="paragraph">§2 Meldung</div>
    <div class="text">
        Nicht rechtzeitiges ab/-zusagen vor
        <ul>1. Spiel (2 Tage)</ul>
        <ul>2. Training (1 Tag) </ul>
        wird mit 5 € sanktioniert.
    </div>
    <div class="paragraph">§3 zu spät erscheinen</div>
    <div class="text">
        Der Trainer bestimmt eine angemessene Bestrafung.
    </div>
    <div class="paragraph">§4 Platzdienst</div>
    <div class="text">
        Wer die Aufgaben vernachlässigt, hat je nach Schwere der Schuld mindestens 2€ bis max. 10€ zu zahlen
    </div>
    <div class="paragraph">§5 unentschuldigtes fernbleiben von einem Spiel</div>
    <div class="text">
        25€ in die Mannschaftskasse <br/>UND <br/> eine Kiste für die Mannschaft.
    </div>

    <div class="container">
        <bm-platzdienst>
        </bm-platzdienst>
    </div>