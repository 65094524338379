<h2>Daten bearbeiten</h2>
<form class="example-form" [formGroup]="addForm" (ngSubmit)="submitForm()">

  <mat-form-field appearance="fill">
    <mat-label>Spielort</mat-label>
    <mat-select formControlName="idspielort" name="ort">
      <mat-option>None</mat-option>
      <mat-option *ngFor="let ort of spielorte" [value]="ort.idspielort">
        {{ort.spielstaette}}
      </mat-option>
    </mat-select>
  </mat-form-field>

    <mat-form-field class="example-full-width">
        <mat-label>Ort des Treffpunkts</mat-label>
        <textarea matInput formControlName="treffOrt"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label></mat-label>
        <input type="time" matInput formControlName="treffZeit">
    </mat-form-field>


    <mat-form-field class="example-full-width">
        <mat-label>Zusatzinformationen</mat-label>
        <textarea matInput formControlName="zusatz"></textarea>
    </mat-form-field>

    <mat-form-field appearance="fill">
        <mat-label>Ergebnis</mat-label>
        <input type="text" matInput formControlName="ergebnis">
    </mat-form-field>



    <button mat-flat-button color="primary" [disabled]="addForm.invalid" type="submit">Treffpunkt speichern</button>

</form>
