import { Component, Input, OnInit } from '@angular/core';
import { Verein } from '../shared/verein';

@Component({
  selector: 'bm-vereine-liste-item',
  templateUrl: './vereine-liste-item.component.html',
  styleUrls: ['./vereine-liste-item.component.css']
})
export class VereineListeItemComponent implements OnInit {
  @Input() vereine: Verein;
  constructor() { }

  ngOnInit(): void {
  }

}
