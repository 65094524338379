import { Component, Input, OnInit } from '@angular/core';
import { Spieler } from '../spieler';

@Component({
  selector: 'bm-multi-spieler-item',
  templateUrl: './multi-spieler-item.component.html',
  styleUrls: ['./multi-spieler-item.component.css']
})
export class MultiSpielerItemComponent implements OnInit {
  @Input() spieler: Spieler;

  constructor() { }

  ngOnInit(): void {
  }

}
