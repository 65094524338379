import { Component, OnInit } from '@angular/core';
import {ThemePalette} from '@angular/material/core';
import { DarkmodeService } from '../shared/darkmode.service';
import { SwUpdate } from '@angular/service-worker';
import { WebNotificationService } from '../web-notification.service';
import { BookStoreService } from '../shared/book-store.service';
import { DialogService } from '../service/dialog.service';
import { Spieler } from '../shared/spieler';

interface Saison {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'bm-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {
  permission: NotificationPermission | null = null;
  color: ThemePalette = 'accent';
  checked = false;
  disabled = false;
  max = 20;
  min = 1;
  showTicks = false;
  step = 1;
  thumbLabel = false;
  value: number;
  curTeam: string = "";
  admin: boolean;
  labelPosition: number;


  foods: Saison[] = [
    {value: '6', viewValue: '23/24'},
    {value: '7', viewValue: '24/25'},
  ];

  constructor(
    public readonly darkmode: DarkmodeService,
    private swUpdate: SwUpdate,
    private webNotificationService: WebNotificationService,
    private bs: BookStoreService,
    private dialog: DialogService
  ) {
    this.admin = (localStorage.getItem('admin') == "true")
    this.labelPosition = parseInt(localStorage.getItem('team'))
  }

  ngOnInit(): void {
    this.value = parseInt(localStorage.getItem('vl'))
    this.curTeam = localStorage.getItem('team')
    console.log("saison" + this.bs.getCurSaison());

    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((evt) => {
        const updateApp = window.confirm(`
          Ein Update ist verfügbar (${evt.current.appData['version']} => ${evt.available.appData['version']}).
          Änderungen: ${evt.current.appData['changelog']}
          Wollen Sie das Update jetzt installieren?
        `);
        if (updateApp) { window.location.reload(); }
      });
      this.permission = this.webNotificationService.isEnabled ? Notification.permission : null;
    }
  }
  submitNotification() {
    this.webNotificationService.subscribeToNotifications()
      .then(() => this.permission = Notification.permission);
  }

  changeTeam(val: number) {
    console.log(val);

    if(localStorage.getItem('team') == "4" )
      this.curTeam = "Alte Herren";
      this.curTeam = localStorage.getItem('team')
  }

  public team(event?: any): void {
    console.log(event.target.innerText);
    let val = event.target.innerText;
    if(val === 'Zweite') {
      localStorage.setItem('team', '2')
      this.curTeam = "zweiten"
    } else if (val === 'Dritte') {
      localStorage.setItem('team', '3')
      this.curTeam = "dritten"
    } else if (val === 'Erste') {
      localStorage.setItem('team', '1')
      this.curTeam = "erste"
    } else if (val === 'Alte Herren') {
      localStorage.setItem('team', '4')
      this.curTeam = "alte herren"
    }
    //this.changeTeam(val);
  }

  formatLabel(value: number) {
    //console.log(value);
    localStorage.setItem('vl',value.toString());

    return value;
  }

  chSeason(val: number) {
    console.log(val);
    if(val != this.bs.getCurSaison()) {
      if(this.dialog.openConfirmDialog("Saison wechseln?").afterClosed().subscribe()) {
        //saison über service wechseln
        // und auf local storage
        console.log(val);
        this.bs.chSaison(val)
      }
    }

  }

  save(val: string) {
    const name = val.split(';')
    let spieler: Spieler = {
      vorname: name[0],
      name: name[1],
      email: "",
      team: 3,
      geburtstag: "",
      bild: "",
    }
    console.log(spieler);
    this.bs.addSpieler(spieler).subscribe(res => {
      console.log(res);

    })
  }


  toggle(){
    this.darkmode.toggleDarkmode();
}

}
