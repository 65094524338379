import { Component, Input, OnInit } from '@angular/core';
import { Kontoauszug } from '../../shared/kontoauszug';




@Component({
  selector: 'bm-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.css']
})


export class TableComponent implements OnInit {
  @Input() kontoauszug: Kontoauszug[];

  displayedColumns: string[] = ['id', 'datum', 'erlauterung', 'betrag'];
  constructor() { }

  ngOnInit(): void {
    console.log(this.kontoauszug);

  }

}
