import { Component, OnInit } from '@angular/core';
import { BookStoreService } from '../shared/book-store.service';
import { SpielerSpieltag } from '../shared/spieler-spieltag';
import { Spieltag } from '../shared/spieltag';

export interface Dienst {
  name:string;
  value:number;
}
export interface SmList {
  spielid: number;
  datum: string
  gegner: string;
  dienst?: Dienst[];
}


@Component({
  selector: 'bm-saison-liste',
  templateUrl: './saison-liste.component.html',
  styleUrls: ['./saison-liste.component.css']
})

export class SaisonListeComponent implements OnInit {
  displayedColumns: string[] = ['position', 'name', 'weight', 'symbol'];

  data: SpielerSpieltag[] = []
  list: SmList[] = []
  dienste: Dienst[]
  //spieltage: number[] = []
  spieltage: Spieltag[] = []

  constructor(
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {
    this.bs.getUebersicht(5).toPromise().then(res => {
      if(res) {
        // console.log(res);
        this.data = res;
        this.getZuordnung()

        //this.getSpieltage()
      }
    })
    this.bs.getAllSpiele(['5','3']).toPromise().then(res => {
      //console.log(res);
      this.spieltage = res
    })
  }

  async getZuordnung() {
    this.bs.getAllSpiele(['5','3']).toPromise().then(res => {
      if(res) this.spieltage = res

      for(let st of res) {
        let sm: SmList = {
          spielid: st.idspieltag,
          datum: st.datum,
          gegner: st.gegner,
          dienst: []
        }
        for(let d of this.data) {
          if(st.idspieltag == d.idspieltag && d.extra) {
            let dienst: Dienst = {
              name: d.name,
              value:d.extra
            }
            sm.dienst.push(dienst)

          }
        }
        this.list.push(sm)

      }
      console.log(this.list);
      this.sort()
    })


  }

  async sort() {
    for(let item of this.list) {
      item.dienst.sort()
    }
  }





  async getSpieltage() {
    for(let item of this.data) {
      // if( this.spieltage.indexOf(item.idspieltag) <0 ) {
      //   this.spieltage.push(item.idspieltag)
      // }

      // this.spieltage.push(item.idspieltag)

    }
    console.log(this.spieltage);
    for(let item of this.spieltage) {

    }
  }

}
