<div class="login-wrapper" fxLayout="row" fxLayoutAlign="center center">
    <mat-card class="box">
        <mat-card-header>
            <mat-card-title>Register</mat-card-title>
        </mat-card-header>

        <form class="example-form" [formGroup]="profilForm">

            <mat-card-content>
                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Vorname" formControlName="vorname">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Nachname" formControlName="name">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput type="date" placeholder="Geburtsdatum" formControlName="datum">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput placeholder="Email" formControlName="email">
                </mat-form-field>

                <mat-form-field class="example-full-width">
                    <input matInput type="password" placeholder="Password" formControlName="passwort">
                </mat-form-field>
            </mat-card-content>

            <button mat-stroked-button color="accent" [disabled]="!profilForm.valid" class="btn-block" (click)="submit()">Register</button>

        </form>
    </mat-card>
</div>
