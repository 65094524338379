import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { retry, catchError } from 'rxjs/operators';
import { throwError, Observable, of } from 'rxjs';


// import { Book } from './book'
import { Spieler } from './spieler';
import { Spieltag } from './spieltag';
import { Training, Training2 } from './training';
import { SpielerSpieltag } from './spieler-spieltag';
import { Spielort } from './spielort';
import { Spielleiter } from './spielleiter'
import { Verein } from './verein';
import { Meldung } from './meldung';
import { Kontostand } from './kontostand';
import { Kontoauszug } from './kontoauszug';
import { Zusatzinfos } from './zusatzinfos';
import { Buchung, SpielerAnzahlSpiel} from '../admin/shared/spieler-anzahl-spiel'
import { HttpHeaders } from '@angular/common/http';
import { Bilanz } from './bilanz';
import { Kontostaende } from './kontostaende';
import { Traindata, TraindataExtendet, TrainingDefault } from './trainData';
import { Termin } from './termin';
import { Trikots } from './trikots';

interface Season {
  season: number;
  year: string;
}

@Injectable({
  providedIn: 'root'
})
export class BookStoreService {
  private ownApi = 'https://svezwei.de/assets/api';
  // private ownApi = 'http://localhost/svedrei/php';
  spieler: Spieler[];

  saison: number = 7;

  seasons: Season[] = [
    {
      season:6,
      year:'23/24'
    },
    {
      season:7,
      year:'24/25'
    }
  ]

  constructor(private http: HttpClient) { }


  /********************************************************************************************************************
                                                Hilfsfunktionen

  *******************************************************************************************************************/

  admin():boolean {
    if(localStorage.getItem('admin')==='true') {
      return true
    }
    return false
  }

  getSeasons(): Observable<Season[]> {
    return of(this.seasons)
  }


  getCurSaison():number {
    return this.saison;
  }

  chSaison(val: number) {
    this.saison = val;
  }

  getJahrDerSaison(val: number):string {
    let saison = '';
    switch(val) {
      case 0:
        console.log('zweite saison');
        saison = '18/19'
        break;
      case 2:
        console.log('dritte saison');
        saison = '19/20'
        break;
      case 3:
        console.log('vierte saison');
        saison = '20/21'
        break;
      case 4:
        console.log('vierte saison');
        saison = '21/22'
        break;
      case 5:
        console.log('fünfte saison');
        saison = '22/23'
        break;
      case 6:
        console.log('sechste saison');
        saison = '23/24'
        break;
      case 7:
          console.log('sechste saison');
          saison = '23/24'
          break;
      default:
        console.log('keine Ahung');
        break;
    }
    return saison
  }

  getVorlauf(): string {
    if (localStorage.getItem('vl')) {
      return localStorage.getItem('vl');
    } else {
      return '3'
    }
  }

  getTeam():number {
    return parseInt(localStorage.getItem('team'))
  }

  setTeam(team: number):void {
    localStorage.setItem('team', team.toString())
  }

  getDefSetting(): Observable<TrainingDefault> {
    return this.http.get<TrainingDefault>(`${this.ownApi}/getSettings.php?id=1`).pipe(retry(3),
    catchError(this.errorHandler));
  }
  /********************************************************************************************************************
                                                Trikots

  *******************************************************************************************************************/
  getTrikots(): Observable<Trikots[]> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json; charset=utf-8' });
    return this.http.get(
      `${this.ownApi}/getTrikots.php`,
    ).pipe(
      catchError(this.errorHandler)
    )
  }

  /********************************************************************************************************************
                                                local

  *******************************************************************************************************************/

  getLauf():Observable<any> {
    return this.http.get<any>("assets/lauf.json")
  }

  /********************************************************************************************************************
                                                Schiris

  *******************************************************************************************************************/

getAllSchiri(): Observable<Spielleiter[]> {
  const httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json'
    })
  };
  return this.http.get<Spielleiter[]>(`${this.ownApi}/getAllSchiri.php`, httpOptions);
}

getSchiri(): Observable<Spielleiter[]> {
  const headers = new HttpHeaders({'Content-Type':'application/json; charset=utf-8'});
  console.log(headers);
  return this.http.post(
    `${this.ownApi}/getAllSchiri.php`,
    headers
  ).pipe(
    catchError(this.errorHandler)
  )
}

getSingleSchiri(id: string): Observable<Spielleiter> {
  return this.http.get<Spielleiter>(`${this.ownApi}/getSingleSchiri.php?id=${id}`).pipe(retry(3),
  catchError(this.errorHandler));
}
  /********************************************************************************************************************
                                               TEST

  *******************************************************************************************************************/
  getSingleSpiel2(id: string): Observable<Spieltag> {
    return this.http.get<Spieltag>(`${this.ownApi}/test/test.php?id=${id}`).pipe(retry(3),
    catchError(this.errorHandler));
  }


  /********************************************************************************************************************
                                                GET

  *******************************************************************************************************************/
  async getAllSpielerA(geb: boolean) {
    let active = true;

    let team = this.getTeam();
    return this.http.get<Spieler[]>(`${this.ownApi}/read.php?geb=${geb}&team=${team}&active=${active}`)
  }

  getAllSpieler(geb: boolean, active: boolean): Observable<Spieler[]> {
    let team = this.getTeam();
    return this.http.get<Spieler[]>(`${this.ownApi}/read.php?geb=${geb}&team=${team}&active=${active}`)
  }

  // getAll(): Observable<Book[]> {
  //   return this.http.get<BookRaw[]>(`${this.api}/books`)
  //   .pipe(
  //     map(booksRaw =>
  //       booksRaw.map(b => BookFactory.fromRaw(b)),
  //       catchError(this.errorHandler)
  //       )
  //   );
  // }

  getApi():string {
    return this.ownApi;
  }

  // getSingle(isbn: string): Observable<Book> {
  //   return this.http.get<BookRaw>(`${this.api}/book/${isbn}`).pipe(retry(3),map(b => BookFactory.fromRaw(b)),
  //   catchError(this.errorHandler));
  // }

  getSingleTraining(id: string): Observable<Training> {
    return this.http.get<Training>(`${this.ownApi}/getSingleTraining.php?id=${id}`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  getSingleSpiel(id: string): Observable<Spieltag> {
    return this.http.get<Spieltag>(`${this.ownApi}/getSingleSpiel.php?id=${id}`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  getSpielerSpiel(id: string): Observable<SpielerSpieltag[]> {
    return this.http.get<SpielerSpieltag[]>(`${this.ownApi}/getSpielerSpiel.php?id=${id}`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  getDifSpieler(val: number, id: string): Observable<SpielerSpieltag[]> {
    return this.http.get<SpielerSpieltag[]>(`${this.ownApi}/getDifSpieler.php?table=val&id=${id}`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  //*************************************************************************************** */

  //***                                 KONTO                                               */

  //*************************************************************************************** */

  getKontostand(spieler: number, team: number): Observable<Kontostand> {
    return this.http.get<Kontostand>(`${this.ownApi}/getKontostand.php?spieler=${spieler}&team=${team}`,
    //
    ).pipe(retry(2),
    catchError(this.errorHandler));
  }

  getKontoauszug(spieler: number, team: number): Observable<Kontoauszug[]> {
    return this.http.get<Kontoauszug[]>(`${this.ownApi}/getKontoauszug.php?spieler=${spieler}&team=${team}`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  getKontostandListe(team: number): Observable<Kontostaende[]> {
    return this.http.get<Kontostaende[]>(`${this.ownApi}/kasse/getKontostaende.php?team=3`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  getTeamBilanz(team: number): Observable<Bilanz> {
    return this.http.get<Bilanz>(`${this.ownApi}/kasse/getTeam.php?team=3`).pipe(retry(3),
    catchError(this.errorHandler));
  }

  //*************************************************************************************** */

  //***                                 REST                                               */

  //*************************************************************************************** */




  getSpielerTrainings(): Observable<any> {
    const saison = this.getCurSaison()
    return this.http.get<Spieler>(`${this.ownApi}/getTrainingsSpieler.php?saison=${saison}`);
  }

  getSpieler(id: string): Observable<Spieler> {
    return this.http.get<Spieler>(`${this.ownApi}/getSpieler.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getVerein(id: number): Observable<Verein> {
    return this.http.get<Verein>(`${this.ownApi}/getVerein.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }
  getSpielort(id: number): Observable<Spielort> {
    return this.http.get<Spielort>(`${this.ownApi}/getSpielort.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getSpieltagByVerein(id: number): Observable<Spieltag> {
    return this.http.get<Spieltag>(`${this.ownApi}/getSpieltagByVerein.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getAllSpiele(para: string[]): Observable<Spieltag[]> {
    let saison = para[0];
    let team = para[1];
    console.log(saison + ' ' + team)
    return this.http.get<Spieltag[]>(`${this.ownApi}/getSpiele.php?saison=${saison}&team=${team}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getAllVereine(): Observable<Verein[]> {
    return this.http.get<Verein[]>(`${this.ownApi}/getAllVereine.php`).pipe(retry(3),catchError(this.errorHandler));
  }

  getAllVereineByName(name: string): Observable<Verein[]> {
    return this.http.get<Verein[]>(`${this.ownApi}/getVereinByName.php?name=${name}`).pipe(retry(3),catchError(this.errorHandler));
  }

  //id=0 -> alle Orte
  getAllOrte(id: number): Observable<Spielort[]> {
    return this.http.get<Spielort[]>(`${this.ownApi}/getAllOrte.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getCurSpiele(): Observable<Spieltag[]> {
    let vorlauf = this.getVorlauf();
    let saison = this.getCurSaison();
    return this.http.get<Spieltag[]>(`${this.ownApi}/getDashboardData.php?wochen=${vorlauf}&saison=${saison}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getUebersicht(saison: number): Observable<SpielerSpieltag[]> {
    return this.http.get<SpielerSpieltag[]>(`${this.ownApi}/getSpielerUebersicht.php?saison=${saison}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getAllTraining(saison: number): Observable<Training[]> {
    return this.http.get<Training[]>(`${this.ownApi}/getAllTraining.php?saison=${saison}`).pipe(retry(3),catchError(this.errorHandler));
  }

  getCurTraining(): Observable<Training[]> {
    let saison = this.getCurSaison();
    let vl = this.getVorlauf();
    return this.http.get<Training[]>(`${this.ownApi}/getCurTraining.php?wochen=${vl}&saison=${saison}`).pipe(retry(3),catchError(this.errorHandler));
  }

   //*************************************************************************************** */

  //***                                 Termin                                               */

  //*************************************************************************************** */

  createTermin(t: Termin) {
    return this.http.post(`${this.ownApi}/create/addTermin.php`,
     t,)
  }
  getTermineA(){
    return this.http.get<Termin[]>(`${this.ownApi}/getTermine.php`
    ).pipe(retry(3),catchError(this.errorHandler));
  }

 getTermine(): Observable<Termin[]> {
  const headers = new HttpHeaders()
  .set('content-type', 'application/json')
  .set('Access-Control-Allow-Origin', '*');
    return this.http.get<Termin[]>(`${this.ownApi}/getTermine.php`)
    .pipe(retry(3),catchError(this.errorHandler));
  }

  getTermin(id:number): Observable<Termin> {
    return this.http.get<Termin>(`${this.ownApi}/getTermin.php?id=${id}`).pipe(retry(3),catchError(this.errorHandler));
  }

/********************************************************************************************************************
                                             Abrechnung

/********************************************************************************************************************/

getSpielerSpielMonat(monat: number): Observable<SpielerAnzahlSpiel[]> {
  let saison: number = this.getCurSaison()
  let team = parseInt(localStorage.getItem('team'));
  console.log(team);

  return this.http.get<SpielerAnzahlSpiel[]>(`${this.ownApi}/kasse/getSpielerSpielMonat.php?team=${team}&saison=${saison}&monat=${monat}`).pipe(retry(3),catchError(this.errorHandler));
}

belasteSpieler(buchung: Buchung): Observable<string> {
  console.log(buchung)
  return this.http.post(
    `${this.ownApi}/kasse/buchen.php`,
    buchung,
    {responseType: 'text'}
  ).pipe(
    catchError(this.errorHandler)
  )
}

buchen(buchen: Buchung): Observable<string> {
  return this.http.post( `${this.ownApi}/kasse/buchen.php`, buchen,  {responseType: 'text'}).pipe(
    catchError(this.errorHandler)
  )
}


  /********************************************************************************************************************/
  //                                            löschen

  /********************************************************************************************************************/

  removeGame(id: number): Observable<any> {
    //console.log(id);
    const params = new HttpParams()
    .set('id', id.toString());
    return this.http.delete<Spieltag>(`${this.ownApi}/del/delSpieltag.php/?id=${id}`);
  }
  removeTraining(id: number): Observable<any> {
    //console.log(id);
    const params = new HttpParams()
    .set('id', id.toString());
    return this.http.delete<Spieltag>(`${this.ownApi}/del/delTraining.php/?id=${id}`);
  }


  /********************************************************************************************************************/


  /********************************************************************************************************************/

  // create(book: Book): Observable<any> {
  //   return this.http.post(
  //     `${this.api}/book`,
  //     book,
  //     { responseType: 'text' }
  //   ).pipe(
  //     catchError(this.errorHandler)
  //   );
  // }

  addSpieler(spieler: Spieler): Observable<any> {
    console.log(spieler);
    return this.http.post(`${this.ownApi}/create/addSpieler.php`,
    spieler
    ).pipe(
      catchError(this.errorHandler)
    );
  }

  createSpieltag(st: Spieltag) {
    console.log(st);

    //idVerein;spielort
    if(st.kommentar) {
      let details = st.kommentar.split(";")
      st.vereinId = parseInt(details[0])
      st.idspielort = parseInt(details[1])
    }
    //let details = st.kommentar.split(";")

    //st.team = 3;
    // if(st.idspielort != 1) {
    //   st.idspielort = 24;
    // }
    //console.log(st);
    this.http.post<Spieltag>(`${this.ownApi}/create/createSpieltag.php`,
     st
    ).subscribe({
      next: data => {
        console.log(data);
      },
      error: error => {
       console.error('There was an error!', error);
      }
    })
   }

  createOrt(ort: Spielort) {
   this.http.post(`${this.ownApi}/create/addOrt.php`,
    ort, { responseType: 'text' }
   ).subscribe({
     next: data => {
       console.log(data);
     },
     error: error => {
      console.error('There was an error!', error);
     }
   })
  }

  createVerein(verein: Verein) {
    //console.log(verein);
    this.http.post(`${this.ownApi}/create/createVerein.php`,
     verein, { responseType: 'text' }
    ).subscribe({
      next: data => {
        console.log(data);
      },
      error: error => {
       console.error('There was an error!', error);
      }
    })
   }

  /********************************************************************************************************************/
                                              //Training

  /********************************************************************************************************************/
  createTraining(training: Training) {
    //console.log(training);
    this.http.post(`${this.ownApi}/create/addTraining.php`,
     training, { responseType: 'text' }
    ).subscribe({
      next: data => {
        console.log(data);
      },
      error: error => {
       console.error('There was an error!', error);
      }
    })
   }

   createTrainData(td: Traindata): Observable<any> {
    //console.log(td);
    return this.http.post(`${this.ownApi}/create/createTrainData.php`, td)
   }

   getAllTraindata(saison: number):Observable<any> {
    return this.http.get<Spieler[]>(`${this.ownApi}/getAllTrainData.php?saison=${saison}`).pipe(retry(3),catchError(this.errorHandler));
   }

   updateTraining(training: Training) {
    // console.log(training);
    return this.http.post(`${this.ownApi}/update/updateTraining.php`, training)
   }

   addTraining(training: Training):Observable<any> {
     return this.http.post<any>(`${this.ownApi}/create/addTraining.php`,
     training)
   }
   addTraining2(training: Training2):Observable<any> {
    return this.http.post<any>(`${this.ownApi}/create/addTraining.php`,
    training)
  }
  updateMeldung(meldung: Meldung): Observable<string> {
  console.log(meldung);
  if (meldung.table == "vtspielerveranstaltung") {
      console.log("veranstaltung");
      return this.http.post(`${this.ownApi}/update/updateVeranstaltung.php`,
      meldung,
      {responseType: 'text'}
    ).pipe(
      catchError(this.errorHandler)
    );
  } else {
      return this.http.post(`${this.ownApi}/update/updateMeldung.php`,
      meldung,
      {responseType: 'text'}
    ).pipe(
      catchError(this.errorHandler)
    );
  }


}

getSpielerImTrainingProMonat(monat: number, saison: number, pflicht: number, kw: number):Observable<SpielerSpieltag[]> {
  return this.http.get<SpielerSpieltag[]>(`${this.ownApi}/getSpielerImTrainingMonat.php?monat=${monat}&saison=${saison}&pflicht=${pflicht}&kw=${kw}`)
  //return this.http.get<SpielerSpieltag[]>(`http://svezwei.de/assets/api/getSpielerImTrainingMonat.php?monat=1&saison=5&pflicht=0&kw=4`)
}

  public melde(userData: Meldung):Observable<any> {
    console.log(userData);
    return this.http.post(`${this.ownApi}/trainMeldung.php`, userData, { responseType: 'text' });
  }

   meldung(meldung: Meldung):string {
    console.log(meldung);
    this.http.post<any>(`${this.ownApi}/trainMeldung.php`,
     meldung, { observe: 'response' }
    ).subscribe({
      next: data => {
        //console.log("bs:" + data + ":bs");
        if(data) {
          return '1';
        }
        return false;
      },
      error: error => {
       console.error('There was an error!', error);
      }
    })
    return 'false';
   }


  private errorHandler(error: HttpErrorResponse): Observable<any> {
    console.error('Fehler aufgetreten');
    return throwError(error);
  }


  /********************************************************************************************************************/
  //                                             UPDATE

  /********************************************************************************************************************/

  updateSpielerZusazu(spieler: Spieler) {
    return this.http.get(`${this.ownApi}/update/updateSpieler.php?spielerzusatz=${spieler.spielerzusatzId}&aktiv=${spieler.aktiv}`)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  updateInfos(infos: Zusatzinfos): Observable<any> {
    return this.http.post(
      `${this.ownApi}/update/updateZusatzinfos.php`,
      infos,
      {responseType: 'text' }
    ).pipe(
      catchError(this.errorHandler)
    )
  }

  updateSpieltag(st: Spieltag):Observable<any> {
    const id = st.idspieltag;
    console.log(st.idspieltag);

    return this.http.post(
      `${this.ownApi}/update/updateSpieltag.php?id=${id}`,
      st,
      {responseType: 'text' }
    ).pipe(
      catchError(this.errorHandler)
    )
  }

  updateSpieler(spieler: Spieler, id: number): Observable<any> {
    return this.http.post(`${this.ownApi}/update/updateSpieler.php?id=${id}`,
    spieler)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  updatePassword(pw: string, id: number): Observable<any> {
    const obj = {
      password: pw,
      id: id
    }
    return this.http.post(`${this.ownApi}/update/updatePassword.php`,
    obj)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  updatePlatzdienst(id: number, spieler: number, spiel: boolean):Observable<any> {
    return this.http.get(`${this.ownApi}/update/updatePlatzdienst.php?id=${id}&spieler=${spieler}&spiel=${spiel}`)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  updateSpielSchiri(id: number, schiri: number) {
    //console.log(id + schiri);
    return this.http.get(`${this.ownApi}/update/updateSchiriSpiel.php?id=${id}&schiri=${schiri}`)
    .pipe(
      catchError(this.errorHandler)
    )
  }

  setBewertung(spielerid: number, spielid: number, sterne: number) {
    console.log("spielerid: "+spielerid + " spiel: "+spielid +" sterne: "+sterne);
    return this.http.get(`${this.ownApi}/update/setBewertung.php?spielerid=1&spielid=365&sterne=3`)

    .pipe(
      catchError(this.errorHandler)
    )
  }

}
