import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { AuthService } from '../shared/auth.service';
import { BookStoreService } from '../shared/book-store.service';
import { Meldung } from '../shared/meldung';
import { SpielerSpieltag } from '../shared/spieler-spieltag';
import { DialogService } from '../service/dialog.service';
import { Spieler } from '../shared/spieler';
import { ActivatedRoute } from '@angular/router';


@Component({
  selector: 'bm-spieler-training',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './spieler-training.component.html',
  styleUrls: ['./spieler-training.component.css']
})
export class SpielerTrainingComponent implements OnInit, OnChanges {
  @Input() spieler: SpielerSpieltag;
  changelog: string[] = [];
  // event 1 für Spiel, 2 für training
  @Input() event: number;


  @Output() parent: EventEmitter<any>= new EventEmitter
  @Output() neuladen: EventEmitter<any>= new EventEmitter
  @Output() newItemEvent = new EventEmitter<SpielerSpieltag>();

  public mode: boolean = false;
  admin: boolean;
  text: number;

  id: number;


  constructor
  (
    private as: AuthService,
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private dialog: DialogService
    )
    {
    this.admin = this.as.isAdmin();
   }

  ngOnInit(): void {
    console.log("event:" + this.event);
    const params = this.route.snapshot.paramMap;
    const id = params.get('id');
    this.id = parseInt(params.get('id'));
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log("Geändert");

  }

  ummelden(status: number): void {

      let meldung = new Meldung();
      if(this.event == 2) {
        meldung.table = "vt_training_spieler"
      } else if (this.event == 1) {
        console.log("spiel");
        meldung.table ="vtspielerspieltag";
      } else if (this.event > 9) {
        meldung.table ="vtspielerveranstaltung";
      }

      meldung.idspieler = this.spieler.idspieler;
      meldung.idevent = this.id;
      meldung.status = status;
      this.dialog.openConfirmDialog("Wirklich ummelden?").afterClosed().subscribe(res => {
        if(res) {
          this.bs.updateMeldung(meldung).toPromise().then(( res => {
            if(res == "1") {
              this.dialog.openMessageDialog("okay")
            }
            this.neuladen.emit();
          }))
        }
      })
  }

  abmelden(): void {
    let meldung = new Meldung();
    meldung.table = "vt_training_spieler"
    meldung.idspieler = this.spieler.idspieler;
    meldung.idevent = this.id;
    meldung.status = 2;
    meldung.extra = this.spieler.extra;
    //console.log("abmelden "+ this.id + " " + this.spieler.idspieler);
    //let data = {spieler:this.spieler.idspieler, training: this.id, dabei: 2};
    this.parent.emit(meldung);
  }

  dienst(id: number, extra: number){
    //console.log("extra" + extra);
    if(extra)
      id = 12
    if(this.event == 1) {
      const meldung: Meldung = {
        table:"vtspielerspieltag",
        //spieler Id vom dienst muss geholt werden
        idspieler:this.spieler.idspieler,
        idevent: this.id,
        extra: id
      }
      console.log(meldung);
      this.bs.updateMeldung(meldung).toPromise().then(res => {
        if(res)
        this.dialog.openMessageDialog(res)
        this.neuladen.emit();

      })

    }
    if(this.event == 2) {
      console.log("training");
      if(!extra) {
        console.log("kein dienst " + id);
        const meldung: Meldung = {
          table:"vt_training_spieler",
          idspieler:this.spieler.idspieler,
          idevent: this.id,
          status: this.spieler.dabei,
          extra: id
        }
        console.log(meldung);
        this.bs.updateMeldung(meldung).toPromise().then(res => {
          if(res)
          this.dialog.openMessageDialog(res)
          this.neuladen.emit();
        })

      } else {
        this.dialog.openMessageDialog("dienst schon vorhanden")
      }
    }


  }

  anmelden(): void {
    let data = {spieler:this.spieler.idspieler, training: this.id, dabei: 1};
    this.parent.emit(data);
  }

  removePlayer() {
    console.log("remove " + this.spieler);
    this.newItemEvent.emit(this.spieler);
  }

  test(spieler: Spieler) {
    let meldung = new Meldung();
    meldung.table = "vt_training_spieler"
    meldung.idspieler = this.spieler.idspieler;
    meldung.status = spieler.dabei
    meldung.idevent = this.id;
    meldung.extra = null;
    console.log('====================================');
    console.log(meldung);
    console.log('====================================');
    this.dialog.openConfirmDialog("Dienst zurücknehmen?").afterClosed().subscribe(res => {
      if(res) {
        this.bs.updateMeldung(meldung).toPromise().then(res =>  {
          this.dialog.openMessageDialog("OK")
        })
      }
    })
  }


}
