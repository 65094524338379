<div class="top">
    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>more_vert</mat-icon>
</button>
    <mat-menu #menu="matMenu">
        <button mat-menu-item routerLink="profil" routerLinkActive="active">
    <mat-icon>insert_emoticon</mat-icon>
    <span>Profil</span>
  </button>
        <button mat-menu-item routerLink="strafen" routerLinkActive="active">
    <mat-icon>sports_soccer</mat-icon>
    <span>Platzdienst und Strafen</span>
  </button>
        <button mat-menu-item routerLink="spieler" routerLinkActive="active">
    <mat-icon>groups</mat-icon>
    <span>Spieler</span>
  </button>
        <button mat-menu-item routerLink="statistik" routerLinkActive="active">
    <mat-icon>groups</mat-icon>
    <span>Statistiken</span>
  </button>
        <button mat-menu-item routerLink="saisonliste" routerLinkActive="active">
    <mat-icon>sports_soccer</mat-icon>
    <span>Übersicht</span>
  </button>


        <button mat-menu-item routerLink="spiele" routerLinkActive="active">
    <mat-icon>sports_soccer</mat-icon>
    <span>Spiele</span>
  </button>
        <button mat-menu-item routerLink="help" routerLinkActive="active">
    <mat-icon>directions_run</mat-icon>
    <span>Hilfe</span>
  </button>
        <button mat-menu-item routerLink="verein" routerLinkActive="active">
    <mat-icon>business</mat-icon>
    <span>Vereine</span>
  </button>
        <!--        <button mat-menu-item routerLink="schiedsrichter" routerLinkActive="active">
    <mat-icon>emoji_people</mat-icon>
    <span>Spielleiter</span>
  </button> -->
    </mat-menu>

    <button class="menuicon" mat-icon-button routerLink="home" routerLinkActive="active">
<mat-icon>home</mat-icon>
</button>

    <button class="menuicon" mat-icon-button routerLink="settings" routerLinkActive="active">
<mat-icon>settings</mat-icon>
</button>

    <button *ngIf="adminmode" mat-icon-button [matMenuTriggerFor]="adminMenu" class="menuicon" aria-label="Example icon-button with a menu">
  <mat-icon>mode_edit</mat-icon>
</button>


    <mat-menu #adminMenu="matMenu">
        <button mat-menu-item routerLink="createSpieltag" routerLinkActive="active">
      <mat-icon>sports_soccer</mat-icon>
      <span>Neues Spiel</span>
    </button>
        <button mat-menu-item routerLink="createTraining" routerLinkActive="active">
      <mat-icon>directions_runs</mat-icon>
      <span>Neues Training</span>
    </button>
    <button mat-menu-item routerLink="createData" routerLinkActive="active">
      <mat-icon>fitness_center</mat-icon>
      <span>Neue Laufdaten</span>
    </button>
        <button mat-menu-item routerLink="createOrt" routerLinkActive="active">
      <mat-icon>golf_course</mat-icon>
      <span>Neuer Spielort</span>
    </button>
        <button mat-menu-item routerLink="createClub" routerLinkActive="active">
      <mat-icon>add_task</mat-icon>
      <span>Neuer Verein</span>
    </button>

        <button mat-menu-item routerLink="admin/neuerTermin" routerLinkActive="active">
      <mat-icon>directions_runs</mat-icon>
      <span>Neuer Termin</span>
    </button>
    <button mat-menu-item routerLink="admin/trikots" routerLinkActive="active">
      <mat-icon>accessibility_new</mat-icon>
      <span>Trikosets</span>
    </button>
    </mat-menu>

    <button *ngIf="adminmode" mat-icon-button [matMenuTriggerFor]="kasseMenu" class="menuicon" aria-label="Example icon-button with a menu">
      <mat-icon>payment</mat-icon>
    </button>


    <mat-menu #kasseMenu="matMenu">

        <button mat-menu-item routerLink="buchen" routerLinkActive="active">
        <mat-icon>payment</mat-icon>
        <span>Buchung</span>
      </button>


        <button mat-menu-item *ngIf="adminmode" routerLink="admin/kasse" routerLinkActive="active">
        <mat-icon>payment</mat-icon>
        <span>Abrechnung</span>
        </button>
        <button mat-menu-item *ngIf="adminmode" routerLink="kontostandliste" routerLinkActive="active">
          <mat-icon>payment</mat-icon>
          <span>Kontostand-Liste</span>
          </button>

    </mat-menu>

    <!-- <button class="profil">
      <ng-container *ngIf="profil$ | async as p">{{p.vorname + " "+p.name}}

      </ng-container>
      <ng-container *ngIf="kontostand$ | async as k">{{k.betrag}}€

      </ng-container>
</button> -->


    <button mat-icon-button class="rechts" (click)="logout()">
<mat-icon>logout</mat-icon>
</button>
</div>
