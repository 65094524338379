
<h1>Spiele:</h1>



<div>
<!-- <div [@inOutAnimation]> -->
  <ng-container *ngIf="spieltag$ | async as spiele">
    <bm-spieltag-item
      class="item"
      *ngFor="let s of spiele"
      [spieltag]="s"
      [routerLink]="['/spiele', s.idspieltag]"
    ></bm-spieltag-item>
    <p class="noInfo" *ngIf="!spiele.length">Keine Spiele diese Woche.</p>
  </ng-container>

  <ng-template #loading>
    <div class="ui active dimmer">
      <div class="ui large text loader">Daten werden geladen...</div>
    </div>
  </ng-template>
</div>

<div>
  <!-- <h1>Top 3 der Rangliste:</h1>

  <ng-container *ngIf="rangliste">
    <div class="punkte" *ngFor="let spieler of rangliste">
      <bm-multi-spieler-item [spieler]="spieler"></bm-multi-spieler-item>
    </div>
  </ng-container> -->

  <h1>Training:</h1>

  <div *ngIf="training$ | async as trainings">
    <!-- {{trainings.length}} -->
    <div *ngFor="let t of trainings; index as i" >
      {{(i+1)*250 +"ms"}}

      <div [@test]="(i+1)*250 +'ms'">
        <bm-training-item
        class="item"
        [i]="i"
        [training]="t"

        [routerLink]="['/training/', t.idTraining]"
      ></bm-training-item>
      </div>

    </div>
  </div>

  <h1>Termine:</h1>

  <div *ngIf="termine$">
    <ng-container *ngIf="termine$ | async as termine">
      <ng-container *ngFor="let t of termine; index as i">
        <div @test>
          <bm-termin-item
          class="item"
          [termin]="t"
          [routerLink]="['/termin/', t.id]"
        ></bm-termin-item>
        </div>

        <p class="noInfo" *ngIf="!termine.length">Kein Termine gefunden.</p>
      </ng-container>
    </ng-container>

    <ng-template #loading>
      <div class="ui active dimmer">
        <div class="ui large text loader">Daten werden geladen...</div>
      </div>
    </ng-template>
  </div>

  <div>
    <ng-container *ngIf="spieler$ | async as spieler; else loading">
      <ng-container *ngFor="let s of spieler">
        <bm-spieler-item
          class="item"
          [spieler]="s"
          [site]="'home'"
          geburtstag="true"
        ></bm-spieler-item>
      </ng-container>

      <!-- <bm-spieler-item *ngFor="let s of spieler" [spieler]="s"></bm-spieler-item> -->
    </ng-container>

    <ng-template #loading>
      <div class="ui active dimmer">
        <div class="ui large text loader">Daten werden geladen...</div>
      </div>
    </ng-template>
  </div>
</div>
