<h1 style="text-align:center">
    SVE Login
</h1>


<mat-card class="box">
    <mat-card-content>
        <form [formGroup]="authForm" (ngSubmit)="login()">
            <mat-error *ngIf="isSubmitted && formControls.email.errors">
                The username and password were not recognised
            </mat-error>
            <mat-form-field class="full-width-input">
                <input matInput placeholder="Email" formControlName="email" required>
                <mat-error>
                    Please provide a valid email address
                </mat-error>
            </mat-form-field>
            <mat-form-field class="full-width-input">
                <input matInput type="password" placeholder="Password" formControlName="password" required>
                <mat-error>


                </mat-error>
            </mat-form-field>
            <button mat-raised-button color="primary">Login</button>
        </form>


    </mat-card-content>
    <a routerLink="../register" routerLinkActive="active">hier zum Registrieren</a>
</mat-card>
