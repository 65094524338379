import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'bm-strafen',
  templateUrl: './strafen.component.html',
  styleUrls: ['./strafen.component.css']
})
export class StrafenComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
