<div *ngIf="spieltag$ | async as spiel">
    <div class="ui divided items">
        <div class="item">


            <div [@flyInL] class="image">
                <img class="ui tiny image" src="../../../assets/teams/{{spiel.verein.logo}}" alt="{{spiel.verein.name}}">
            </div>
            <div [@flyInR] class="content">
                <a class="header">{{spiel.gegner}} <div class="result" *ngIf="status === 'past'">Ergebnis: {{spiel.zusatz.ergebnis}}</div> </a>
                <div class="meta">
                    <span class="cinema">{{spiel.datum | date:'longDate'}}</span>
                </div>
                <div class="description">
                    <ng-container *ngIf="spiel.typ === 'Meisterschaft'">
                        <h2> {{spiel.spieltag}}. Spieltag</h2>
                    </ng-container>
                    <p *ngIf="spiel.typ === 'Freundschaftsspiel'">Freundschaftsspiel</p>
                    <p *ngIf="spiel.typ === 'Pokalspiel'">Pokalspiel</p>
                    <p>Anstoß ist um {{spiel.datum | date:'shortTime'}} Uhr </p>
                    <div *ngIf="spiel.spielort.spielstaette != 24">
                        <p class="ort"> Spielort: {{spiel.spielort.spielstaette}}</p>
                        <p class="ort">{{spiel.spielort.strasse }}</p>
                        <p class="ort">{{spiel.spielort.plz +" " + spiel.spielort.ort }}</p>
                    </div>
                    <div class="count">
                        <!-- <div class="meldungen">{{spieltag.spieler.length}}
                            <mat-icon aria-hidden="false" aria-label="Example home icon">thumbs_up_down</mat-icon>
                        </div> -->
                        <div class="dabei">{{spielerDabei.length}}
                            <mat-icon aria-hidden="false" aria-label="Example home icon">thumb_up</mat-icon>
                        </div>
                        <div class="raus">{{spielerRaus.length}}
                            <mat-icon aria-hidden="false" aria-label="Example home icon">thumb_down</mat-icon>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <bm-kalender-button mat-fab-button [typ]="typ" [spiel]="spiel" color="primary">
            <mat-icon class="mat-18">ligature</mat-icon>
        </bm-kalender-button>


        <mat-slide-toggle (change)="change($event)">
            <div *ngIf="!tacticalMode">zum Exportmodus</div>
            <div *ngIf="tacticalMode">normale Ansicht</div>
        </mat-slide-toggle>

<!-- ------------------------------------------------------------------------------------
 ----------------------------------------------------------------------------------------

                          Daten bearbeiten

----------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------->
        <div data-aos="fade-up" data-aos-duration="100" *ngIf="!printMode && !tacticalMode">
            <mat-accordion *ngIf="adminmode">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            Daten bearbeiten
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <button mat-flat-button color="warn" (click)="openDialog()">Spiel löschen</button>
                    <button mat-flat-button color="primary" (click)="edit($event)">Spieldaten anpassen</button>
                    <!-- <button mat-flat-button color="warn" (click)="edit()">Treffpunkt festlegen</button> -->

                    <mat-form-field appearance="fill">
                        <mat-label>Spielleiter</mat-label>
                        <mat-select [(ngModel)]="selected" (selectionChange)='onOptionsSelected($event)'>
                            <mat-option *ngFor="let item of spielleiter" [value]="item.idspielleiter">
                                {{item.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>



                </mat-expansion-panel>
            </mat-accordion>
        </div>


        <bm-add-spiel-daten *ngIf="editMode" [spieltag]="spieltag"></bm-add-spiel-daten>


<!-- ------------------------------------------------------------------------------------
 ----------------------------------------------------------------------------------------

                     Spieler verwalten

----------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------->

        <div data-aos="fade-up" data-aos-duration="250" *ngIf="!printMode && !tacticalMode">
            <mat-accordion *ngIf="adminmode">
                <mat-expansion-panel (opened)="panelOpenState = true" (closed)="panelOpenState = false">

                    <mat-expansion-panel-header>
                        <mat-panel-description>
                            Spieler verwalten
                        </mat-panel-description>
                    </mat-expansion-panel-header>

                    <bm-spieler-meldung *ngIf="alleSpieler" (newItemEvent)="addItem($event)" [isSpiel]="isSpiel" [id]="id" [spieler]="alleSpieler" [adminmode]="adminmode"></bm-spieler-meldung>

                </mat-expansion-panel>
            </mat-accordion>
        </div>


<!-- ------------------------------------------------------------------------------------
 ----------------------------------------------------------------------------------------

                                       BUTTONS

----------------------------------------------------------------------------------------
---------------------------------------------------------------------------------------->
        <div *ngIf="!printMode && !tacticalMode && !zeitAbgelaufen && showAnmeldebuttons" class="ui two buttons">
            <button class="ui button" (click)="melde(2)">Absagen</button>
            <div class="or"></div>
            <button class="ui positive button" (click)="melde(1)">Zusagen</button>
        </div>

        <div class="content" *ngIf="zeitAbgelaufen && !printMode && !tacticalMode">
          <div class="alert alert-warning alert-white rounded">
            <button type="button" class="close" data-dismiss="alert" aria-hidden="true">×</button>
            <div class="icon"><i class="bi bi-bell"></i></div>
            <strong>Meldund nicht mehr möglich!</strong> Frist ist abgelaufen.
        </div>
      </div>



    </div>


    <div class="spielerbox" cdkDrop (cdkDropDropped)="dropped($event)">
        <div *ngIf="!tacticalMode">
            <bm-spieler-training *ngFor="let s of spiel.spieler" [spieler]="s" [event]=1 (newItemEvent)="addItem($event)"></bm-spieler-training>
        </div>

        <div class="export" id="export" *ngIf="tacticalMode">
          <button class="btn" (click)="switchNumber()">Nummern ein- oder ausblenden</button>
          <!-- {{spiel.spieler|json}} -->
          <div class="liste" *ngFor="let s of spiel.spieler; index as i">
            {{s.vorname + " "+ s.name}}
            <ng-container *ngIf="showNumber"> {{s.nummer}} </ng-container>

            <ng-container *ngIf="s.extra == 1">*V</ng-container>
            <ng-container *ngIf="s.extra == 2">*P</ng-container>
            <ng-container *ngIf="s.extra == 12">*VP</ng-container>

          </div>
          V: Verkaufsdienst <br>
          P: Platzdienst<br>

        </div>

        <ng-template #loading>
            <p>Keine Spieler gefunden oder gemeldet</p>
        </ng-template>
    </div>
</div>

