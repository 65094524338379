import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { DialogService } from 'src/app/service/dialog.service';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { PwMatchValidator } from 'src/app/shared/pw-match-validator'

@Component({
  selector: 'bm-new-password',
  templateUrl: './new-password.component.html',
  styleUrls: ['./new-password.component.css']
})
export class NewPasswordComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA)
  public idspieler: number,
  private fb: FormBuilder,
  private dialog: DialogService,
  private bs: BookStoreService,
  public dialogRef: MatDialogRef<NewPasswordComponent>) { }

  pwForm = this.fb.group({
    password1: ['',
    Validators.required,
    Validators.minLength(4),
  ],
    password2: ''
  }, {validator: PwMatchValidator.passwordEquality });


  ngOnInit(): void {
  }

  save() {
    this.bs.updatePassword(this.pwForm.value.password1, this.idspieler).toPromise().then( res => {
      if(res) {
        this.dialog.openMessageDialog("OK")
        this.closeDialog(true)
      } else {
        console.log("Fehler");
        this.dialog.openMessageDialog("Fehler")

      }
    })
  }

  closeDialog(val: boolean) {
    this.dialogRef.close(val);
  }


}
