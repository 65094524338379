<div class="container">

    <div class="content" *ngIf="termin">
        <div *ngIf="termin.kategorie == 1" class="image">
            <img class="ui tiny image" src="../../assets/icons/feier.png">
        </div>
        <div *ngIf="termin.kategorie == 2" class="image">
            <img class="ui tiny image" src="../../assets/icons/arbeit.png">
        </div>

    </div>
    <div *ngIf="termin" class="content">
        <h1>{{termin.name}}</h1>
        <p>{{termin.datum | date : 'shortDate'}}</p>

        <p class="kommentar">{{termin.kommentar}}</p>

    </div>
    <bm-kalender-button mat-fab-button [typ]="typ" [termin]="termin" color="primary">
      <mat-icon class="mat-18">ligature</mat-icon>
  </bm-kalender-button>

</div>

<div class="ui two buttons" *ngIf="showButton">
    <button class="ui button " (click)="melde(2) ">Absagen</button>
    <div class="or "></div>
    <button class="ui positive button " (click)="melde(1) ">Zusagen</button>
</div>



<div *ngIf="spieler">
<<<<<<< HEAD
=======
    <!-- <bm-spieler-spieltag *ngFor="let s of spieler" [spieler]="s" [mode]="termin.kategorie"> </bm-spieler-spieltag> -->

>>>>>>> a04115ccc1d5d1444f248e0736b312caad2ab5e7
    <bm-spieler-training *ngFor="let s of spieler" [spieler]="s" [id]="termin.id" [event]="termin.kategorie+termin.kategorie"></bm-spieler-training>
</div>
