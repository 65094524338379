<h2>Buchen</h2>
<form [formGroup]="form">
    <div class="example-container">
        <mat-form-field appearance="fill">
            <mat-label>Spieler</mat-label>
            <mat-select formControlName="spielerid">
                <mat-option></mat-option>
                <mat-option [value]="option.idspieler" *ngFor="let option of spieler">{{ option.name +", "+ option.vorname }}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field appearance="fill" floatLabel="always">
            <mat-label>Betrag</mat-label>
            <input matInput type="number" class="example-right-align" placeholder="0" formControlName="betrag">
            <span matPrefix>€&nbsp;</span>
            <span matSuffix>,00</span>
        </mat-form-field>

        <mat-form-field appearance="fill">
            <mat-label>Betreff</mat-label>
            <input matInput placeholder="Betreff" formControlName="zweck">
        </mat-form-field>
    </div>
</form>

<div>
    <button mat-raised-button color="primary" [disabled]="!form.valid" (click)="buchen()">Buchen</button>
</div>
