import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Verein } from 'src/app/shared/verein';
import { ActivatedRoute, Router } from '@angular/router';
import { Spielort } from 'src/app/shared/spielort';
import { Spieltag } from 'src/app/shared/spieltag';


@Component({
  selector: 'bm-verein-details',
  templateUrl: './verein-details.component.html',
  styleUrls: ['./verein-details.component.css']
})
export class VereinDetailsComponent implements OnInit {
  verein$: Observable<Verein>;
  ort$: Observable<Spielort>;
  spieltag$: Observable<Spieltag>;
  id: number;
  club: Verein;
  spieltage: Spieltag;
  admin: boolean;

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit(): void {
    this.admin = false;
    const params = this.route.snapshot.paramMap;
    this.id = parseInt(params.get('id'));
    this.verein$ = this.bs.getVerein(this.id);
    this.spieltag$ = this.bs.getSpieltagByVerein(this.id)
    //this.ort$ = this.bs.getSpielort(this.getSpielortId());

    this.bs.getSpieltagByVerein(this.id).subscribe(res => this.spieltage = res)
  }

  getSpielortId():number {
    return 19
  }
}
