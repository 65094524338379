import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from  '../auth.service';
import { Observable } from 'rxjs';
import { Spieler } from '../spieler';
import { BookStoreService } from '../book-store.service';
import { Kontostand } from '../kontostand';


@Component({
  selector: 'bm-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.css']
})
export class MenuComponent implements OnInit {
  adminmode=false;
  profil$:Observable<Spieler>
  ks$:Observable<Kontostand>
  kontostand$: Observable<Kontostand>;

  constructor(
    private authService: AuthService,
    private router: Router,
    private bs: BookStoreService,
  ) { }

  ngOnInit(): void {
    //this.profil$=this.bs.getSpieler(localStorage.getItem('auth'))
    //this.kontostand$ = this.bs.getKontostand(parseInt(localStorage.getItem('auth')),parseInt(localStorage.getItem('team')))
    // this.w()
    if( localStorage.getItem('admin') != String(this.adminmode) ) {
      this.adminmode = true;
    }

  }

  async w() {
    await this.delay(500).then(res => {
      if( localStorage.getItem('admin') != String(this.adminmode) ) {
        this.adminmode = true;
        this.ngOnInit()
      }}
    )
    }

  delay(ms: number) {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

  logout() {
    this.authService.logout();
    this.router.navigate(
      ['/login']
    )

  }

}
