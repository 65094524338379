import { state } from '@angular/animations';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkmodeService {
  public currentState: boolean;
  private darkmodeState: BehaviorSubject<boolean> = new BehaviorSubject(this.detectDarkmode());

  constructor(
  ) {
    this.darkmodeState.subscribe((state: boolean) => {
      this.currentState = state;
      if (state) {
        this.setDarkmode();
      } else {
        this.setLightmode();
      }
    })
  }

  public toggleDarkmode(): void {
    this.darkmodeState.next(!this.currentState)
  }

  private detectDarkmode(): boolean {
    const mediaMatch = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return typeof mediaMatch === 'boolean' ? mediaMatch : false;
  }

  private setDarkmode(): void {
    document.body.classList.remove('lightmode');
    document.body.classList.add('darkmode');
  }

  private setLightmode(): void {
    document.body.classList.add('lightmode');
    document.body.classList.remove('darkmode');
  }
}
