import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spieltag } from 'src/app/shared/spieltag';
import { Meldung } from '../../shared/meldung';
import { Zusatzinfos } from 'src/app/shared/zusatzinfos';
import { animate, style, transition, trigger } from '@angular/animations';
import { Spielleiter } from 'src/app/shared/spielleiter';
import { Spieler } from 'src/app/shared/spieler';
import { DialogService } from 'src/app/service/dialog.service';
import Swal from 'sweetalert2'
import { CalenderService } from '../../service/calender.service';

@Component({
  selector: 'bm-spieltag-details',
  templateUrl: './spieltag-details.component.html',
  styleUrls: ['./spieltag-details.component.css'],
  animations: [
    trigger('flyInL', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('500ms')
      ]),
      transition('* => void', [
        animate('300ms', style({transform: 'translateX(100%)'}))
      ])
    ]),
    trigger('flyInR', [
      transition(':enter', [
        style({transform: 'translateX(+100%)'}),
        animate('500ms')
      ]),
      transition('* => void', [
        animate('300ms', style({transform: 'translateX(100%)'}))
      ])
    ]),

    ]
  })
export class SpieltagDetailsComponent implements OnInit {

  spieltag$: Observable<Spieltag>;
  private id: number;
  posts: string;
  adminmode: boolean;
  private spieldaten: boolean;
  private printMode: boolean = false;
  panelOpenState = false;
  tacticalMode: boolean;
  items = [];
  spielleiter: Spielleiter[];
  spieltag: Spieltag;
  status: String;
  bewertet: boolean;
  gemeldet: boolean;
  editMode: boolean = false;
  platzdienst: Spieler;
  alleSpieler: Spieler[];
  isSpiel = true;
  zeitAbgelaufen: boolean = false;
  showAnmeldebuttons: boolean = true;
  countSpieler: number = 0;
  dabei: number = 0;
  raus: number = 0;
  spielerDabei: Spieler[] = [];
  spielerRaus: Spieler[] = [];
  showNumber: boolean = false;
  typ: string = "spiel";

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private router: Router,
    private cs: CalenderService,
    public dialog: DialogService
  ) {
    if(localStorage.getItem('admin')=== 'true') {
      this.adminmode = true;
    } else {
      this.adminmode = false;
    }
    this.spieldaten = false;
    this.tacticalMode = false;
  }

  ngOnInit(): void {

    this.loadData();
    const params = this.route.snapshot.paramMap;
    this.id = parseInt(params.get('id'));
    this.spieltag$ = this.bs.getSingleSpiel(params.get('id'))
    this.bs.getAllSchiri().subscribe(res => this.spielleiter = res)
    this.bs.getAllSpieler(false, true).subscribe(res => {
      if(res) {
        //console.log(res);
        this.alleSpieler = res;

      }
    })
  }



  async loadData() {
    const params = this.route.snapshot.paramMap;
    this.spieltag = await this.bs.getSingleSpiel(params.get('id')).toPromise<Spieltag>()
    for(let item of this.spieltag.spieler) {
      if(item.dabei == 1) this.spielerDabei.push(item)
      else this.spielerRaus.push(item)
      // console.log('====================================');
      // console.log(item);
      // console.log('====================================');
    }
    this.istRechtzeitig(this.spieltag);
    this.isAngemeldet(this.spieltag.spieler);
    //this.bewertet = this.checkBewertung();
  }




  istRechtzeitig(spiel: Spieltag) {
    let jetzt = new Date();
    //anmeldefrist in h
    let abmeldefrist = 40;
    let millisekunden = abmeldefrist * 3600000
    //console.log(spiel.datum);
    let eventDatum = new Date(spiel.datum)
    //console.log(eventDatum.getTime()-millisekunden);
    //console.log(jetzt.getTime());
    if((eventDatum.getTime() - millisekunden) < jetzt.getTime()) {
      this.zeitAbgelaufen = true;
      //console.log("zu spät");
    }
  }

  isAngemeldet(array: Spieler[]) {
    for(let i = 0; i<array.length; i++ ) {
      this.countSpieler = array.length;
      //console.log(array[i]);
      //Anmeldebuttons verschwinden, wenn schon eine Meldung vorliegt
      if(array[i].idspieler == parseInt(localStorage.getItem('auth'))) {this.showAnmeldebuttons = false }
      if(array[i].dabei == 1) {
        this.dabei +=1
      } else if(array[i].dabei==2) {
        this.raus +=1
      }
    }
  }

  checkBewertung():boolean {
    let ich = parseInt(localStorage.getItem('auth'));
    for(let item of this.spieltag.spieler) {
      if (item.idspieler == ich) {
        //console.log(`gemeldet? ${item}wertung: ${item.wertung}`);
        if (item.wertung != 0) {
          //console.log("bewertet= true")
          return true;
        }
      }
    }
    return false;
  }


  addSpieldaten() {
    //console.log("spieldaten");
    this.spieldaten = !this.spieldaten;
  }

  async updateInfos(infos: Zusatzinfos){
    //console.log(infos);
    await this.bs.updateInfos(infos).toPromise().then(async res => {
      if(res) {
        Swal.fire({
          title: 'Custom animation with Animate.css',
          showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
        })
      }
    })
    this.ngOnInit();
  }


  edit(event: Event) {
    this.editMode = !this.editMode;
  }

  async onOptionsSelected(event) {
    //console.log(event)
    const value = event.value;
    //let selected = value;
    console.log(value);
    await this.bs.updateSpielSchiri(this.id, value).toPromise();
    this.ngOnInit();
 }

 async setPlatzdienst(event) {
  //console.log(event)
  const value = event.value;
  //let selected = value;
  console.log(value);
  await this.bs.updatePlatzdienst(this.id, value, true).toPromise();
  this.ngOnInit();
}

  async melde(val: number) {
    const params = this.route.snapshot.paramMap;
    const id = parseInt(params.get('id'));
    const spieler = parseInt(localStorage.getItem('auth'));
    let meldung = new Meldung();
    meldung.table = "vtspielerspieltag";
    meldung.idspieler = spieler;
    meldung.idevent = id;
    meldung.status = val;
    await this.bs.melde(meldung).toPromise().then(async res => {
      if(res) {
        console.log(res);
        await Swal.fire({ toast: true, position: 'top-end', showConfirmButton: false, timer: 3000, title: 'Okay!', text: 'Meldung erfolgreich', icon: 'success', });

        this.ngOnInit()
      }
    })
    //console.log( 'text '+ text);
    // if(text == '1') {
    //   this.ngOnInit();

    // }
  }
  openDialog() {
    let table = 'spieltag';
    if(confirm('Spiel wirklich löschen?')) {
      this.bs.removeGame(this.id).subscribe(
       res => this.router.navigate(
         ['/home']
       )
      );
    }

  }
  change(ev: Event) {
    this.tacticalMode = !this.tacticalMode;
  }

  print() {
    this.printMode = !this.printMode;
    window.print();
  }

  switchNumber() {
    this.showNumber = !this.showNumber
    console.log(this.showNumber);

  }



    bewerte(sterne: number) {
      //console.log(sterne + " spielid= " + this.id + localStorage.getItem('auth'));
      let ich = parseInt(localStorage.getItem('auth'));
      this.bs.setBewertung(ich,this.id,sterne)
    }

    addItem(s: Spieler) {
      console.log(s);
      console.log(this.spieltag.spieler);
      for(let sp of this.spieltag.spieler) {
        console.log(sp);
        if(sp.idspieler == s.idspieler) {
          sp.dabei = 2
        }
      }
      console.log(this.spieltag.spieler);

      //this.ngOnInit();
    }

    kopieren() {
      const liste = document.getElementById('export').innerHTML
      console.log( liste );


    }



}

