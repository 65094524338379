<div class="container">

<ng-container *ngIf="!editing">
  Verein anlegen
</ng-container>


  <form class="ui form" [formGroup]="vereinForm" (ngSubmit)="submitForm()">
    <mat-form-field class="example-full-width">
      <mat-label>Vereinsname</mat-label>
      <input matInput value="name" formControlName="name">

      </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-label>Logo</mat-label>
      <input matInput placeholder="name vom Bild" formControlName="logo" value="png">
    </mat-form-field>

<ng-container *ngIf="spielort$ | async as ort">
  <mat-form-field appearance="fill">
    <mat-label>Heimatplatz</mat-label>
    <mat-select name="spielort" formControlName="idspielort">
      <mat-option *ngFor="let o of ort" [value]="o.idspielort">
        {{o.spielstaette}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>

    <button type="submit" mat-raised-button color="primary" [disabled]="vereinForm.invalid">Abschicken
    </button>



  </form>

