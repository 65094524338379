import { Component, OnInit } from '@angular/core';
import { Training } from '../../shared/training';
import { BookStoreService } from '../../shared/book-store.service';
import {Chart, registerables} from 'node_modules/chart.js'
import { SpielerSpieltag } from '../../shared/spieler-spieltag';
import { Observable } from 'rxjs';
import { Anfrage } from 'src/app/shared/meldung';
Chart.register(...registerables)
@Component({
  selector: 'bm-statistik',
  templateUrl: './statistik.component.html',
  styleUrls: ['./statistik.component.css']
})


export class StatistikComponent implements OnInit {

  training: Training[] = []
  tmp: number[]= []
  labels: string[]= []
  $spieler: Observable<SpielerSpieltag[]>



  constructor(
    private bs: BookStoreService
  ) { }

  addItem(anfrage: Anfrage) {
    console.log(anfrage);
    console.log(anfrage.pflicht);
    this.$spieler = this.bs.getSpielerImTrainingProMonat(anfrage.monat,anfrage.saison,anfrage.pflicht,anfrage.kw);
  }

  ngOnInit(): void {
    const date = new Date();
    const monat = date.getMonth() +1;
    const saison = this.bs.getCurSaison();
    //monat, saison, pflicht, kw
    this.$spieler = this.bs.getSpielerImTrainingProMonat(monat,saison,2,0);
    // this.bs.getSpielerTrainings().subscribe(res => {
    //   console.log(res);
    //   this.training = res;
    //   for(let item of res) {

    //     this.tmp.push(item.spieler.length)
    //     let date = new Date(item.datum).toLocaleDateString()
    //     this.labels.push(date)
    //   }
    //   console.log(this.tmp);

    //   this.renderChart(this.tmp);
    // })
  }

  renderChart(data: number[]) {

// const ctx = document.getElementById('myChart').getContext('2d');
const myChart = new Chart("pichart", {
    type: 'bar',
    data: {
        //labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
        labels: this.labels,
        datasets: [{
            label: '# of Votes',
            //data: [12, 19, 3, 5, 2, 3],
            data: data,
            backgroundColor: [
                'rgba(255, 99, 132, 0.2)',
                'rgba(54, 162, 235, 0.2)',
                'rgba(255, 206, 86, 0.2)',
                'rgba(75, 192, 192, 0.2)',
                'rgba(153, 102, 255, 0.2)',
                'rgba(255, 159, 64, 0.2)'
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)'
            ],
            borderWidth: 1
        }]
    },
    options: {
        scales: {
            y: {
                beginAtZero: true
            }
        }
    }
});

  }

}
