import { Injectable } from '@angular/core';
import { CanActivate, Router  } from '@angular/router';

@Injectable({
  providedIn: 'root'
})


export class IsLoggedInGuard implements CanActivate {

  constructor(
    // private authService: AuthService,
    private route: Router
    ){}


  canActivate(): boolean {
    if(localStorage.getItem('auth')) {
      return true
    } else {
    this.route.navigate(["login"]);
    return false
    }
  }

}
