import { Component, OnInit } from '@angular/core';
import { Kontostaende } from '../../shared/kontostaende';
import { BookStoreService } from '../../shared/book-store.service';
import { DialogService } from '../../service/dialog.service';
import { Buchung } from '../../admin/shared/spieler-anzahl-spiel';


@Component({
  selector: 'bm-kontostand-liste',
  templateUrl: './kontostand-liste.component.html',
  styleUrls: ['./kontostand-liste.component.css']
})
export class KontostandListeComponent implements OnInit {

  displayedColumns: string[] = ['name', 'vorname', 'kontostand'];

  kontostaende: Kontostaende[];
  team = 3;
  limit = -20;
  monat = new Date().getMonth() +1
  summe: number = 0

  constructor(private bs: BookStoreService, private dialog: DialogService) { }

  ngOnInit(): void {
    this.bs.getKontostandListe(this.team).subscribe(res => {
      this.kontostaende = res
      for(let s of res) {
        if(s.kontostand <0) {
          this.delay(700).then(any=>{
            this.summe += s.kontostand*(-1)
       });
        }
      }

    });
  }

  async delay(ms: number) {
    await new Promise<void>(resolve => setTimeout(()=>resolve(), ms)).then(()=>console.log("fired"));
}


  dreiMalZwanzig() {
    for(let s of this.kontostaende) {
      if(s.kontostand <= this.limit) {
        const buchung: Buchung = {
          spielerid: s.idspieler,
          team: 3,
          betrag: Math.round(s.kontostand*0.2),
          zweck: `3x20 Strafe Monat ${this.monat}`
        }
        this.dialog.openConfirmDialog(s.name + " bestrafen?").afterClosed().subscribe(res => {
          if(res) {
            // console.log(buchung);
            this.bs.belasteSpieler(buchung).toPromise().then(res => {
              this.dialog.openMessageDialog("Alle bestraft! :-)")
            })
          }
        })

      }
    }
  }

}
