<div class="container">

<mat-card class="example-card" *ngIf="spieler$ | async as spieler; else loading">
    <mat-card-title-group>
        <mat-card-title>{{ spieler.vorname + ' ' + spieler.name }}
        </mat-card-title>

        <mat-card-subtitle>geb: {{spieler.geburtstag | date: 'shortDate'}}
            <div class="sub">{{spieler.email}}</div>
            <!-- <div>{{spieler |json}}</div> -->

            <button *ngIf="admin" class="sub button-1" (click)="edit()">edit</button>
            <div class="kontostand" *ngIf="kontostand$ | async as k;">
              Kontostand: {{k.kontostand}} €
            </div>
        </mat-card-subtitle>
        <img mat-card-xl-image src="../../../assets/spielerFotos/{{spieler.bild}}">
    </mat-card-title-group>
    <mat-card-content>
    </mat-card-content>
</mat-card>

</div>

<div class="container" *ngIf="kontoauszug$ | async as ka;">
    <ul class="responsive-table">
        <li class="table-header">
            <div class="col col-1">KbewID</div>
            <div class="col col-2">datum</div>
            <div class="col col-3">Bewegung</div>
            <div class="col col-4">Betrag</div>
        </li>


        <ng-container *ngFor="let k of ka">
            <li class="table-row">
                <div class="col col-1">{{k.id}} </div>
                <div class="col col-2">{{k.datum | date: 'longDate'}}</div>
                <div class="col col-3">{{k.erlaeuterung}}</div>
                <div class="col col-4">{{k.betrag}}€</div>
            </li>
        </ng-container>
    </ul>
</div>

<ng-template #loading>
    <div class="ui active centered inline loader"></div>
</ng-template>
