import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BookStoreService } from '../../shared/book-store.service';
import { Spieltag } from '../../shared/spieltag'
@Component({
  selector: 'bm-spiele',
  templateUrl: './spiele.component.html',
  styleUrls: ['./spiele.component.css']
})
export class SpieleComponent implements OnInit {
  spieltag$: Observable<Spieltag[]>;

  checked = false;
  indeterminate = false;
  team: number = parseInt(localStorage.getItem('team'));
  teamPosition: '3' | '2' = '3';
  saisonPosition: '7' | '3' = '7';

  disabled = false;
  saison: number;


  formatLabel(value: number) {
    this.saison = value;
    return value;
  }

  constructor(
    private bs: BookStoreService
    ) { }

  change() {
    //console.log(this.labelPosition +  ' ' + this.saisonPosition );
    let para: Array<string> = [this.saisonPosition, this.teamPosition];
    this.spieltag$ = this.bs.getAllSpiele(para);
  }

  ngOnInit(): void {
   this.change();
  }

}
