<mat-tab-group *ngIf="spieler">
  <mat-tab label="Profil">
    <mat-card class="example-card">
      <mat-card-header>
        <div mat-card-avatar class="example-header-image"></div>
        <mat-card-title>{{
          spieler.vorname + " " + spieler.name
        }}</mat-card-title>
        <mat-card-subtitle
          >Geburtsdatum: {{ spieler.geburtstag }}</mat-card-subtitle
        >
        <mat-card-subtitle>E-Mail: {{ spieler.email }}</mat-card-subtitle>
      </mat-card-header>
      <img
        mat-card-image
        class="image"
        src="../../assets/spielerFotos/{{ spieler.bild }}"
        alt="Profilbild"
      />
      <mat-card-content>
        <p></p>
      </mat-card-content>
      <mat-card-actions>
        <!-- <button mat-button>LIKE</button> -->
        <!-- <a mat-button href="mailto:info@svezwei.de">FEHLER MELDEN</a> -->

        <!-- <a mat-button (click)="abwesenheit()">Abwesenheit eintragen</a> -->
        <!-- <a *ngIf="admin" mat-button (click)="edit()">Daten anpassen</a> -->
      </mat-card-actions>

      <!-- <div *ngIf="editmode">
              <form [formGroup]="profileForm">

                <label for="first-name">First Name: </label>
                <input id="first-name" type="text" formControlName="firstName">

                <label for="last-name">Last Name: </label>
                <input id="last-name" type="text" formControlName="lastName">

              </form>
            </div> -->
      <!-- <div>{{ kontostand | json }}</div> -->
    </mat-card>
  </mat-tab>
  <mat-tab>
    <ng-template *ngIf="kontostand" mat-tab-label>
      <mat-icon class="example-tab-icon">payment</mat-icon>
      Kasse
    </ng-template>

    <div *ngIf="kontostand" class="kontostand">
            Kontostand: {{kontostand.kontostand}} €
        </div>

    <bm-table *ngIf="kontoauszug" [kontoauszug]="kontoauszug"></bm-table>
  </mat-tab>
</mat-tab-group>
