import { Component, Input, OnInit } from '@angular/core';
import { Verein } from '../../shared/verein';
@Component({
  selector: 'bm-auswahl-mannschaft',
  templateUrl: './auswahl-mannschaft.component.html',
  styleUrls: ['./auswahl-mannschaft.component.css']
})
export class AuswahlMannschaftComponent implements OnInit {
  @Input() gegner: Verein;
  counter: number;
  constructor() { }

  ngOnChanges(): void {
    //Called before any other lifecycle hook. Use it to inject dependencies, but avoid any serious work here.
    //Add '${implements OnChanges}' to the class.
    console.log(this.gegner);
    this.counter = this.gegner.mannschaften;
  }

  ngOnInit(): void {
  }

}
