<div class="content-container">
  <div class="head"></div>
  <mat-icon id="close-icon" (click)="closeDialog(false)">close</mat-icon>
  <span class="content-span full-width"></span>
</div>

<form class="example-form" [formGroup]="spielerForm">
  <h2>Spieler</h2>

  <div>id: {{spieler.idspieler}}</div>

  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <label for="vorname">Vorname: </label>
          <input matInput id="vorname" type="text" formControlName="vorname">
        </mat-form-field>
      </td>

      <td>
        <mat-form-field class="example-full-width">
          <label for="name">Name: </label>
          <input matInput id="name" type="text" formControlName="name">

        </mat-form-field>
      </td>
    </tr>
  </table>

  <mat-form-field class="example-full-width">
    <label for="email">E-Mail:</label>
    <input matInput id="email" type="email" formControlName="email">
  </mat-form-field>



  <mat-form-field class="example-full-width">
    <label for="geb">Geburtstag:</label>
    <input matInput id="geb" type="date" formControlName="geburtstag">
  </mat-form-field>


  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <mat-form-field class="example-full-width">
          <label for="bild">Bild: </label>
          <input matInput id="bild" type="text" formControlName="bild">
        </mat-form-field>
      </td>

      <td>
        <mat-form-field class="example-full-width">
          <label for="nummer">Nummer: </label>
          <input matInput id="nummer" type="text" formControlName="option">
        </mat-form-field>
      </td>
    </tr>
  </table>

  <table class="example-full-width" cellspacing="0">
    <tr>
      <td>
        <section class="example-section">
          <mat-checkbox
              class="example-margin"
              [(ngModel)]="spieler.aktiv"
              [(indeterminate)]="indeterminate"
              [labelPosition]="labelPosition"
              formControlName="aktiv">
            Aktiv
          </mat-checkbox>
        </section>
      </td>

      <td>
        <section class="example-section">
          <mat-checkbox
              class="example-margin"
              [(ngModel)]="spieler.admin"
              [(indeterminate)]="indeterminate"
              [labelPosition]="labelPosition"
              formControlName="admin">
            Admin
          </mat-checkbox>
        </section>
      </td>

      <td>
        <mat-form-field class="example-full-width">
          <label for="team">Teamzugehörigkeit: </label>
          <input matInput id="team" type="number" formControlName="team">
        </mat-form-field>
      </td>
    </tr>
  </table>

  <div class="example-button-row">
    <button mat-flat-button color="primary" [disabled]="spielerForm.invalid" (click)="save()">speichern</button>
    <button mat-flat-button color="accent" (click)="closeDialog(false)">Abbruch</button>
    <button mat-flat-button color="warn" (click)="changePW()">neues Passwort</button>
  </div>

  <div>{{spielerForm.status}}</div>



</form>


