export class Meldung {
  table: string;
  idspieler: number;
  idevent: number;
  status?: number;
  zeitstempel?: Date;
  extra?: number;
}


export class Anfrage {
  kw: number;
  monat: number;
  saison: number;
  pflicht: number;
}
