import { Component, Input, OnInit } from '@angular/core';
import { Training } from '../training';
import { Spieltag } from '../spieltag';
import { Termin } from '../termin';

@Component({
  selector: 'bm-kalender-button',
  templateUrl: './kalender-button.component.html',
  styleUrls: ['./kalender-button.component.css']
})
export class KalenderButtonComponent implements OnInit {
  @Input() training: Training;
  @Input() typ: string;
  @Input() spiel: Spieltag;
  @Input() termin: Termin;

  start: string;
  end: string;
  summary: string ="";
  location: string = ""
  constructor() { }

  getTrigger(h: number): string {
    return [
      'BEGIN:VALARM',
      `TRIGGER:-P${h}H`,
      'ACTION:DISPLAY',
      'END:VALARM',
    ].join('\n')
  }



  ngOnInit(): void {

  }


  //
  //                          TRAINING
  //

  //generiert trainingsstart
  makeDate():string {
    let datum = this.training.datum.replace('-', '')
    datum += 'T'+ this.training.zeit +'Z'
    datum = datum.replace("-","")
    datum = datum.replace(":","")
    datum = datum.replace(":","")

    // console.log(datum);
    return datum
  }
  convertDate(s: string): string {

    s = s.replace('-',"")
    s = s.replace('-',"")
    s = s.replace(':',"")
    s = s.replace(':',"")
    s = s.replace('.',"")
    // console.log(s);

    return s
  }

  dateToString(d: Date):string {
    let is = d.toISOString()
    // console.log(is);

    return this.convertDate(is)
  }

  dateToEndDate(start: boolean): string {
    let d = this.training.datum +"T" + this.training.zeit
    let da = new Date(d)
    // console.log(da);
    if(start) {
      da.setHours(da.getHours()+0)
    } else {
      da.setHours(da.getHours()+2)
      da.setSeconds(0)
      // console.log(da);
    }
    return this.dateToString(da)
  }



  getTrainingICS(): string {
    const cal = [
      'data:text/calendar;charset=utf8,BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTAMP:' + this.dateToString( new Date()),
      'DTSTART:' + this.dateToEndDate(true),
      'DTEND:' + this.dateToEndDate(false),
      'SUMMARY: Training',
      `DESCRIPTION:${this.training.zusatz.replace(/[\n\r]/g, '')}`,
      //'LOCATION:' + this.location,
      'UID:' + this.training.idTraining,
      this.getTrigger(1),
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');
    return cal
  }

  //
  //                          SPIEL
  //

  dateToEnd(date: Date, add: number):string {
    date.setHours(date.getHours()+add)
    // console.log(date);
    return this.dateToString(date)
  }

  getSpielICS():string {
    const cal = [
      'data:text/calendar;charset=utf8,BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTAMP:' + this.dateToString( new Date()),
      'DTSTART:' + this.start,
      'DTEND:' + this.end,
      'SUMMARY: '+ this.summary,
      `DESCRIPTION:${this.spiel.kommentar.replace(/[\n\r]/g, '')}`,
      'LOCATION:' + this.location,
      'GEO:48.85299;2.36885',
      'UID:' + this.spiel.idspieltag,
      this.getTrigger(2),
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');
    return cal
  }

  //
  //                          Termin
  //

  getTerminICS():string {
    const cal = [
      'data:text/calendar;charset=utf8,BEGIN:VCALENDAR',
      'VERSION:2.0',
      'BEGIN:VEVENT',
      'DTSTAMP:' + this.dateToString( new Date()),
      'DTSTART:' + this.start,
      'DTEND:' + this.end,
      'SUMMARY: '+ this.summary,
      //`DESCRIPTION:${this.spiel.kommentar.replace(/[\n\r]/g, '')}`,
      'LOCATION:' + this.location,
      'UID:' + this.termin.id,
      this.getTrigger(24),
      'END:VEVENT',
      'END:VCALENDAR',
    ].join('\n');
    return cal
  }


  addToCalendar(): void {
    if(this.typ === 'training') {
      this.makeDate()
      if(!this.training.zusatz) this.training.zusatz = "";
      // console.log(this.getTrainingICS());

      window.open(this.getTrainingICS());

    } else if(this.typ === 'spiel') {
        this.location = this.spiel.spielort.spielstaette +"; " + this.spiel.spielort.strasse + "; " + this.spiel.spielort.plz + " " + this.spiel.spielort.ort
        let date = new Date(this.spiel.datum)
        this.start = this.dateToEnd(date,0)
        this.end = this.dateToEnd(date,2)
        this.summary = `Spiel gegen ${this.spiel.gegner}`
      if(!this.spiel.kommentar) this.spiel.kommentar = "";

      window.open(this.getSpielICS());

    } else if(this.typ === 'termin') {
      console.log("termin");
      console.log(this.termin);
      let date = new Date(this.termin.datum)
      this.start = this.dateToEnd(date,0)
      this.end = this.dateToEnd(date,3)
      if(!this.termin.kommentar) this.summary = ""
      else this.summary = this.termin.kommentar
      this.location = "";
      window.open(this.getTerminICS());
    }

  }

}
