import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Verein } from '../shared/verein'
import { BookStoreService } from '../shared/book-store.service';

@Component({
  selector: 'bm-vereine-liste',
  templateUrl: './vereine-liste.component.html',
  styleUrls: ['./vereine-liste.component.css']
})
export class VereineListeComponent implements OnInit {
  vereine$ : Observable<Verein[]>

  constructor(
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {
    this.vereine$ = this.bs.getAllVereine();
  }

}
