<h1>Ort hinzufügen</h1>

<form class="ui form" [formGroup]="ortForm" (ngSubmit)="onSubmit()">

  <label> Spielstaette:  </label>
    <input type="text" formControlName="spielstaette" placeholder="Spielort">
     <bm-form-messages
    [control]="ortForm.get('spielstaette')"
    controlName="spielstaette">
  </bm-form-messages>


  <label>Strasse:</label>
    <input type="text" formControlName="strasse">
     <bm-form-messages
    [control]="ortForm.get('strasse')"
    controlName="strasse">
  </bm-form-messages>


    <label> PLZ: </label>
    <input type="text" formControlName="plz">
    <bm-form-messages [control]="ortForm.get('plz')" controlName="plz"></bm-form-messages>


    <label> Ort:   </label>
    <input type="text" formControlName="ort">
    <bm-form-messages [control]="ortForm.get('ort')" controlName="ort"></bm-form-messages>


<label>Belag</label>
<select class="ui dropdown"  formControlName="platz">
  <option value="Kunstrasen">Kunstrasen</option>
  <option value="Asche">Asche</option>
  <option value="Rasen">Rasen</option>
  <bm-form-messages [control]="ortForm.get('platz')" controlName="platz"></bm-form-messages>
</select>


  <label>Latitude:   </label>
  <input type="text" formControlName="lat">
  <bm-form-messages [control]="ortForm.get('lat')" controlName="lat"></bm-form-messages>

  <label>Longitude:</label>
  <input type="text" formControlName="lng">
  <bm-form-messages [control]="ortForm.get('lng')" controlName="lng"></bm-form-messages>


<section>
  <div class="example-button-row">
    <button mat-flat-button color="primary" (click)="reset()">Primary</button>
    <button mat-flat-button color="accent" type="submit" [disabled]="ortForm.invalid">Accent</button>
  </div>
</section>
</form>

