import { Component, Input, OnInit } from '@angular/core';
import { Spieler } from '../../shared/spieler'
@Component({
  selector: 'bm-spieler-item',
  templateUrl: './spieler-item.component.html',
  styleUrls: ['./spieler-item.component.css']
})
export class SpielerItemComponent implements OnInit {
  @Input() spieler: Spieler;
  @Input() geburtstag = false;
  @Input() site: string;

  constructor() { }

  ngOnInit(): void {
    //console.log(this.spieler);

  }

}
