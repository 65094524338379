import { Component, Input, OnInit } from '@angular/core';
import { Termin } from 'src/app/shared/termin';

@Component({
  selector: 'bm-termin-item',
  templateUrl: './termin-item.component.html',
  styleUrls: ['./termin-item.component.css']
})
export class TerminItemComponent implements OnInit {
  @Input() termin:Termin;

  date = new Date();
  heute: string;


  constructor() { }


  ngOnInit(): void {
    let monat: string;
    if(this.date.getMonth()<10) {
      monat = '0'+ (this.date.getMonth()+1);
    }
    this.heute = this.date.getDate().toString() +'.'+ monat;
  }

}
