<!-- <div>
    {{setting | json}}
</div> -->

<div>
    <p *ngIf="setting">Hier werden beide Einheiten gleichzeitig für {{setting.trainingTime1}} Uhr erstellt</p>

    <section class="example-section" [formGroup]="days">
      <p><mat-checkbox formControlName="1">Montag</mat-checkbox></p>
      <p><mat-checkbox formControlName="2">Dienstag</mat-checkbox></p>
      <p><mat-checkbox formControlName="3">Mittwoch</mat-checkbox></p>
      <p><mat-checkbox formControlName="4">Donnerstag</mat-checkbox></p>
      <p><mat-checkbox formControlName="5">Freitag</mat-checkbox></p>
    </section>

    <section class="example-section" [formGroup]="days">
      <h4>You chose:</h4>
      {{days.value | json}}
    </section>


    <p>
        <button mat-raised-button color="primary" color="primary" (click)="saveWoche()">speichern</button>
        <!-- <button mat-raised-button color="primary" color="primary" (click)="test()">test okay</button> -->

    </p>
</div>



<h1>Neues Training</h1>

<form class="ui form" [formGroup]="trainForm" (ngSubmit)="submitForm()">

    <section class="example-section">
        <label class="example-margin">Teilnahme:</label>
        <mat-radio-group formControlName="pflicht">
            <mat-radio-button class="example-margin" value="1">Pflicht</mat-radio-button>
            <mat-radio-button class="example-margin" value="0">Freiwillig</mat-radio-button>
        </mat-radio-group>
    </section>

    <section class="example-section">
        <label class="example-margin">Mannschaft:</label>
        <mat-radio-group formControlName="team">
          <mat-radio-button class="example-margin" value="0">Für alle</mat-radio-button>
          <mat-radio-button class="example-margin" value="1">Erste</mat-radio-button>
          <mat-radio-button class="example-margin" value="2">Zweite</mat-radio-button>
          <mat-radio-button class="example-margin" value="3">Dritte</mat-radio-button>
          <mat-radio-button class="example-margin" value="4">AH</mat-radio-button>
        </mat-radio-group>
    </section>

    <section class="example-section">
        <label class="example-margin">Kategorie:</label>
        <mat-radio-group formControlName="kategorie">
            <mat-radio-button class="example-margin" value="training">Training</mat-radio-button>
            <mat-radio-button class="example-margin" value="laufen">Laufen</mat-radio-button>
            <mat-radio-button class="example-margin" value="kicken">Kicken</mat-radio-button>
            <mat-radio-button class="example-margin" value="biken">Biken</mat-radio-button>
            <mat-radio-button class="example-margin" value="Fitness">Fitnesstraining</mat-radio-button>
            <mat-radio-button class="example-margin" value="sonstiges">Sonstiges</mat-radio-button>
        </mat-radio-group>
    </section>


    <div class="ui form">

        <label>
    Datum:
    <input type="date" formControlName="date">
  </label>


        <label>Zeit</label>
        <input type="time" formControlName="time">



        <div class="field">
            <label>weitere Infos:</label>
            <textarea rows="2" formControlName="zusatz"></textarea>
        </div>

    </div>
    <button mat-raised-button color="primary" type="submit" [disabled]="trainForm.invalid">
    Speichern
  </button>
</form>
