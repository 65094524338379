import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { BookStoreService } from '../shared/book-store.service';
import { Spieler } from '../shared/spieler';
import { DialogService } from '../service/dialog.service';

interface Team {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'bm-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent implements OnInit {

  //teams: any = ['Erste', 'Zweite', 'Dritte', 'Alte Herren'];

  teams: Team[] = [
    {value: 'Erste', viewValue: '1'},
    {value: 'Zweite', viewValue: '2'},
    {value: 'Dritte', viewValue: '3'},
    {value: 'Alte Herren', viewValue: '3'}
  ];

  constructor(
    private bs: BookStoreService,
    private fb: FormBuilder,
    private router: Router,
    private dialog: DialogService
  ) { }

  profilForm = this.fb.group({
    vorname: ['', Validators.required],
    name: ['', Validators.required],
    datum: ['', Validators.required],
    email: ['', Validators.required],
    passwort:['', Validators.required],
    //team:['', Validators.required]
  })

  ngOnInit(): void {
    this.initForm()
  }


  initForm() {

  }

  async submit() {
    //console.log(this.profilForm.value);
    const formValue = this.profilForm.value;
    const newSpieler: Spieler = {
      ...formValue,
      email: this.profilForm.value.email.toLowerCase()
    }
    console.log(newSpieler);

    await this.bs.addSpieler(newSpieler).toPromise().then(res => {
      //alert(res);
      if(res) {
        this.dialog.openMessageDialog("Mission erfolgreich")
        this.router.navigate(['login']);
      }
      this.router.navigate(['login']);
    }).catch( error => {
      console.error('There was an error!', error);
      this.dialog.openMessageDialog(JSON.stringify(error))
    })
    .finally(() => {})
  }


}
