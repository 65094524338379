import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Training } from '../../shared/training'

@Component({
  selector: 'bm-training',
  templateUrl: './training.component.html',
  styleUrls: ['./training.component.css']
})
export class TrainingComponent implements OnInit {
  training$: Observable<Training[]>
  saison: number;
  constructor(
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {
    this.saison = this.bs.getCurSaison();
    this.training$ = this.bs.getAllTraining(this.saison);
  }

}
