import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {MatDialog} from '@angular/material/dialog';

import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spieler } from 'src/app/shared/spieler';
import { Kontostand } from '../../shared/kontostand';
import { Kontoauszug } from '../../shared/kontoauszug';
import { DialogService } from '../../service/dialog.service';


@Component({
  selector: 'bm-spieler-details',
  templateUrl: './spieler-details.component.html',
  styleUrls: ['./spieler-details.component.css']
})
export class SpielerDetailsComponent implements OnInit {
  spieler$: Observable<Spieler>
  kontostand$: Observable<Kontostand>
  kontoauszug$: Observable<Kontoauszug[]>
  spieler: Spieler;

  id: number;
  admin: boolean;
  aktiv: boolean

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    public dialog: DialogService
    ) {
      this.admin = this.bs.admin()
    }


  ngOnInit(
  ): void {


    const params =  this.route.snapshot.paramMap;

    this.id = parseInt(params.get('id'));
    this.spieler$ = this.bs.getSpieler(params.get('id'));
    this.bs.getSpieler(params.get('id')).toPromise().then( res => {
      //console.log(res);
      this.spieler=res
      if(this.spieler.aktiv == 1) this.aktiv = true
      else this.aktiv=false

    })


    if(localStorage.getItem('auth')==params.get('id') || this.admin ) {
      //console.log('owner oder admin = true')
      this.kontostand$ = this.bs.getKontostand(parseInt(params.get('id')),3)
      this.kontoauszug$ = this.bs.getKontoauszug( parseInt(params.get('id')),3);
    }
  }

  edit() {
    this.dialog.openEditSpieler(this.spieler)
  }
}
