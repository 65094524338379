<mat-card *ngIf="verein$ | async as verein">

    <mat-card-header>
        <ng-container>
            <img mat-card-sm-image src="../../../assets/teams/{{verein.logo}}" alt="Verinswappen">
            <mat-card-title>
                {{verein.name}}
            </mat-card-title>
        </ng-container>
    </mat-card-header>
    <mat-card-subtitle>
        <h3>Heimatplatz:</h3>
        <p>{{verein.ort.strasse +' in ' + verein.ort.plz +' '+ verein.ort.ort}}</p>
        <p>{{verein.ort.platz}}</p>
    </mat-card-subtitle>


</mat-card>

<ng-container *ngIf="spieltag$ | async as spieltag">
    <div *ngFor="let s of spieltag">

    </div>
</ng-container>

<a *ngIf="admin" class="ui tiny yellow labeled icon button" [routerLink]="['../../verein/edit/', 1]">
    <i class="write icon"></i>Verein bearbeiten
</a>


<table>
    <thead>
        <tr>
            <th>Team</th>
            <th>Ergebnis</th>
            <th>Gegner</th>
            <th>Datum</th>
        </tr>
    </thead>
    <tbody>
        <ng-container *ngIf="spieltag$ | async as spieltag">
            <tr *ngFor="let s of spieltag">
                <td>SVE {{s.team}}</td>
                <td>{{s.ergebnis}}</td>
                <td>{{s.gegner}}</td>
                <td>{{s.datum | date: 'longDate'}}</td>
            </tr>
        </ng-container>
    </tbody>
</table>
