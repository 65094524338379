


<mat-card class="example-card">
  <mat-card-header>
    <div mat-card-avatar><img class="ui mini image" src="../../assets/teams/{{vereine.logo}}"> </div>
    <mat-card-title>{{vereine.name}}</mat-card-title>
    <mat-card-subtitle></mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button>mehr</button>

  </mat-card-actions>
</mat-card>
