import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, FormArray, Validators, RequiredValidator } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spielort } from 'src/app/shared/spielort';


@Component({
  selector: 'bm-create-ort',
  templateUrl: './create-ort.component.html',
  styleUrls: ['./create-ort.component.css']
})
export class CreateOrtComponent implements OnInit {

ortForm: FormGroup;

  constructor(
    private bs: BookStoreService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    this.initForm();
  }

  private initForm() {
    if(this.ortForm) { return; }

    this.ortForm = this.fb.group({
      spielstaette: ['', Validators.required],
      strasse: ['', Validators.required],
      plz: ['', [
        Validators.required,
        Validators.maxLength(5),
        Validators.minLength(5)
      ]],
      ort: ['', Validators.required],
      platz: ['', Validators.required],
      lat: ['', Validators.minLength(8)],
      lng: ['', Validators.minLength(8)]
    })
  }

  reset() {
    this.ortForm.reset();
  }

  onSubmit() {
    // TODO: Use EventEmitter with form value
    //console.log(this.profileForm.value);
    const formValue = this.ortForm.value;
    const newOrt: Spielort = {
      ...formValue
    };
    //console.log('neuer ort:'+ newOrt);
    this.bs.createOrt(newOrt);
    this.ortForm.reset();
  }
}
