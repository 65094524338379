import { Component, OnInit } from '@angular/core';
import { Spieler } from '../../shared/spieler'
import { Observable } from 'rxjs';
import { BookStoreService } from 'src/app/shared/book-store.service';

@Component({
  selector: 'bm-spieler-liste',
  templateUrl: './spieler-liste.component.html',
  styleUrls: ['./spieler-liste.component.css']
})
export class SpielerListeComponent implements OnInit {
  spieler: Spieler[];

  constructor(private bs: BookStoreService) { }

  ngOnInit(): void {
    this.bs.getAllSpieler(false, false).subscribe(res => {
      console.log(res);
      this.spieler = res
    })
  }
}
