<h2>Hilfe</h2>

<!-- <div class="video-player-wrapper">

    <vg-player (onPlayerReady)="videoPlayerInit($event)">
        <vg-overlay-play></vg-overlay-play>
        <vg-buffering></vg-buffering>

        <vg-scrub-bar>
            <vg-scrub-bar-current-time></vg-scrub-bar-current-time>
            <vg-scrub-bar-buffering-time></vg-scrub-bar-buffering-time>
        </vg-scrub-bar>

        <vg-controls>
            <vg-play-pause></vg-play-pause>
            <vg-playback-button></vg-playback-button>

            <vg-time-display vgProperty="current" vgFormat="mm:ss"></vg-time-display>

            <vg-time-display vgProperty="total" vgFormat="mm:ss"></vg-time-display>

            <vg-mute></vg-mute>
            <vg-volume></vg-volume>

            <vg-fullscreen></vg-fullscreen>
        </vg-controls>

        <video #media [vgMedia]="$any(media)" [src]="currentVideo.src" id="singleVideo" preload="auto" crossorigin>
    </video>
    </vg-player>

    <ul class="player-list">
        <li *ngFor="let vdo of videoItems; let $index = index" (click)="startPlaylistVdo(vdo, $index)" [class.selected]="vdo === currentVideo">
            {{ vdo.name }}
        </li>
    </ul>

</div> -->
