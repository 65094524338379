import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';


interface Saison {
  value: number;
  jahre: string;
}

interface KeyValue {
  key: number;
  value: string;
}
@Component({
  selector: 'bm-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.css']
})
export class FormComponent implements OnInit {
  @Output() newItemEvent = new EventEmitter<string>();

  form: FormGroup;

  wochen: string[] = [
    "Erste", "Zweite", "Dritte", "Vierte", "Fünfte"
  ]

  wochenArray: number[] = []

  monate: KeyValue[] = [
    {key:1, value: "Januar"},
    {key:2, value: "Februar"},
    {key:3, value: "März"},
    {key:4, value: "April"},
    {key:5, value: "Mai"},
    {key:6, value: "Juni"},
    {key:7, value: "Juli"},
    {key:8, value: "August"},
    {key:9, value: "September"},
    {key:10, value: "Oktober"},
    {key:11, value: "November"},
    {key:12, value: "Dezember"},
  ]
  saisons: Saison[] = [
    {value: 5, jahre: '22/23'},
  ]


  monatControl = new FormControl(this.monate[0].key);
  saisonControl = new FormControl(this.saisons[0].value);
  wocheControl = new FormControl(0);
  pflichtControl = new FormControl(2);

  constructor() {

    this.form = new FormGroup({
      kw: this.wocheControl,
      monat: this.monatControl,
      saison: this.saisonControl,
      pflicht: this.pflichtControl,
    });
  }

  ngOnInit(): void {

    for(let i=0;i<=53;i++) {
      this.wochenArray.push(i)
    }
    console.log(this.wochenArray);

  }

  choose() {
    console.log(this.form.value);
    const choosen = this.form.value
    this.newItemEvent.emit(choosen);
  }

}
