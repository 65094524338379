import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogService } from 'src/app/service/dialog.service';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { Spieler } from 'src/app/shared/spieler';

@Component({
  selector: 'bm-spieler-edit',
  templateUrl: './spieler-edit.component.html',
  styleUrls: ['./spieler-edit.component.css']
})
export class SpielerEditComponent implements OnInit {

  indeterminate = false;
  labelPosition: 'before' | 'after' = 'after';
  disabled = false;

  // spielerForm: FormGroup

  constructor(@Inject(MAT_DIALOG_DATA)
  public spieler: Spieler,
  private fb: FormBuilder,
  private dialog: DialogService,
  private bs: BookStoreService,
  public dialogRef: MatDialogRef<SpielerEditComponent>) { }


  initForm() {
    if(this.spielerForm) {return}

  }

  spielerForm = this.fb.group({
    vorname: [this.spieler.vorname, Validators.required],
    name: [this.spieler.name, Validators.required],
    email: [this.spieler.email, [Validators.required, Validators.email]],
    geburtstag: [this.spieler.geburtstag],
    option: [this.spieler.option],
    bild: [this.spieler.bild],
    aktiv: [this.spieler.aktiv],
    team: [this.spieler.team],
    admin: [this.spieler.admin],
  });

  ngOnInit(): void {
    console.log(this.spieler);

  }

  save() {
    console.log("save");
    let aktiv = 0;
    if(this.spielerForm.value.aktiv)
      aktiv = 1
    else
      aktiv = 0
    const updatedSpieler: Spieler = {

      ...this.spielerForm.value,

      aktiv: aktiv,
      idspieler: this.spieler.idspieler,
      spielerzusatzId: this.spieler.spielerzusatzId
    }
    console.log(updatedSpieler);
    this.dialog.openConfirmDialog("Spieler speichern?").afterClosed().subscribe(res => {
      if(res) {

        console.log("wird gespeichert");
        this.bs.updateSpieler(updatedSpieler, this.spieler.idspieler).toPromise().then(res => {
          if(res) {
            this.dialog.openMessageDialog("OK")
          } else {
            this.dialog.openMessageDialog(res)
          }
        })

      } else {
        console.log("Abbruch");

      }
    })
  }

  changePW() {
    this.dialog.newPassword(this.spieler.idspieler)
  }

  closeDialog(val: boolean) {
    this.dialogRef.close(val);
  }

}
