import { Component, Input, OnInit } from '@angular/core';
import { Spieltag } from 'src/app/shared/spieltag';

@Component({
  selector: 'bm-spieltag-item',
  templateUrl: './spieltag-item.component.html',
  styleUrls: ['./spieltag-item.component.css']
})
export class SpieltagItemComponent implements OnInit {
  @Input() spieltag: Spieltag;

  date = new Date();
  heute: string;

  constructor() { }

  ngOnInit(): void {
    let monat: string;
    if(this.date.getMonth()<10) {
      monat = '0'+ (this.date.getMonth()+1);
    }
    this.heute = this.date.getDate().toString() +'.'+ monat;
  }

}
