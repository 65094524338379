<mat-card class="example-card">
    <mat-card-header>
        <div mat-card-avatar>
            <ng-container *ngIf="termin.kategorie == 1">
                <img class="ui mini image" src="../../../assets/icons/feier.png">
            </ng-container>
            <ng-container *ngIf="termin.kategorie == 2">
                <img class=" ui mini image " src="../../../assets/icons/arbeit.png ">
            </ng-container>
            <ng-container *ngIf="termin.kategorie==3">
                <img class="ui mini image " src="../../../assets/icons/helfen.png ">
            </ng-container>
        </div>
        <mat-card-title>
            <h1 class="team ">{{termin.name | uppercase}}</h1>
        </mat-card-title>
        <mat-card-subtitle>Am: {{termin.datum | date:'shortDate' }} </mat-card-subtitle>
    </mat-card-header>
    <mat-card-content>
        <!-- <p>{{termin.kommentar}}</p> -->
    </mat-card-content>
    <!--
  <mat-card-actions>
    <button mat-button>mehr</button>
  </mat-card-actions>-->
</mat-card>


<ng-template #elseBlock><img class="ui tiny image " src="../../../assets/icons/biken.svg "></ng-template>
