<bm-menu>






</bm-menu>
<div class="wrapper">
    <router-outlet></router-outlet>


</div>
