import { FormGroup, ValidationErrors } from "@angular/forms";

export class PwMatchValidator {
  static passwordEquality(controlGroup: FormGroup): ValidationErrors | null {
    const pw1 = controlGroup.get('password1').value;
    const pw2 = controlGroup.get('password2').value;

    if(pw1 === pw2) {
      return null
    } else {
      return {
        passwordEquality: {valid: false}
      }
    }
  }
}
