

  <mat-card class="example-card" [ngClass]="{'raus feld': spieler.dabei == 2,
    'rein feld': spieler.dabei == 1
    }">
    <mat-card-header>
        <div mat-card-avatar class="example-header-image">
            <ng-container *ngIf="spieler.bild; else elseBlock">
                <img src="../../../assets/spielerFotos/{{spieler.bild}}">
            </ng-container>
            <!-- <mat-card-title class="title">{{spieler.nummer}}</mat-card-title> -->

        </div>
        <mat-card-title class="title">
            <div class="nummer" *ngIf="spieler.nummer > 0">
                {{spieler.nummer}}
            </div>
            <div class="name">
                {{spieler.vorname +" "+ spieler.name}}
            </div>


        </mat-card-title>
        <mat-card-subtitle class="meldung"> Meldung am: {{spieler.datum | date:'medium' }}</mat-card-subtitle>

        <mat-chip-list aria-label="Fish selection">
            <mat-chip *ngIf="spieler.extra == 1" (click)="test(spieler)" color="primary" selected>THEKENDIENST</mat-chip>
            <mat-chip *ngIf="spieler.extra == 2" (click)="test(spieler)" color="accent" selected>PLATZDIENST</mat-chip>
            <mat-chip *ngIf="spieler.extra == 12" (click)="test(spieler)" color="primary" selected>THEKENDIENST</mat-chip>
            <mat-chip *ngIf="spieler.extra == 12" (click)="test(spieler)" color="accent" selected>PLATZDIENST</mat-chip>
        </mat-chip-list>

    </mat-card-header>
    <!-- <mat-card-content class="content">
        <mat-icon *ngIf="spieler.dabei == '1'" aria-hidden="false" aria-label="Example home icon">thumb_up</mat-icon>
        <mat-icon *ngIf="spieler.dabei == '2'" aria-hidden="false" aria-label="Example home icon">thumb_down</mat-icon>
    </mat-card-content> -->
    <mat-card-actions *ngIf="admin">
        <!-- <button *ngIf="spieler.dabei == '1'" mat-raised-button (click)="removePlayer()">TEST</button> -->

        <button *ngIf="spieler.dabei == '1'" mat-raised-button (click)="ummelden(2)">ABMELDEN</button>
        <button *ngIf="spieler.dabei == '2'" mat-raised-button (click)="ummelden(1)">ANMELDEN</button>
        <ng-container *ngIf="event <= 9">
          <button mat-raised-button (click)="dienst(1,spieler.extra)">THEKENDIENST</button>
          <button mat-raised-button (click)="dienst(2,spieler.extra)">PLATZDIENST</button>
        </ng-container>


    </mat-card-actions>
</mat-card>

<ng-template #elseBlock><img class="image" src="../../../assets/spielerFotos/ohne.jpg"></ng-template>

