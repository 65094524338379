import { Spieler } from "./spieler";

export class Training {
  idTraining?: number;
  datum: string;
  zeit: string;
  zusatz?: string;
  kategorie: string;
  pflicht: number;
  team: string;
  spieler?: Spieler[];
  saison: number;

}
export class Training2 {
  idTraining?: number;
  date: string;
  time: string;
  zusatz?: string;
  kategorie: string;
  pflicht: number;
  team: string;
  spieler?: Spieler[];
  saison: number;
}
