<h2>Platzdienst</h2>

Diese Seite beschreibt die Arbeiten, für die der Platzdienst verantwortlich ist. Der Platzdienst wird durch Trainer und Kassenwart aufgrund eines hohen Minus in der Mannschaftskasse oder durch andere Unannehmlichkeiten bestimmt.


<mat-list role="list">
    <mat-list-item role="listitem">
        Trikots bei Jens einsammeln oder wen dafür finden und auch wieder zu ihm bringen!
    </mat-list-item>
    <mat-list-item role="listitem">
        Müll, Kleidungsstücke, etc aus den Kabinen räumen
    </mat-list-item>
    <mat-list-item role="listitem">
        Wasserflaschen bereitstellen
    </mat-list-item>
    <mat-list-item role="listitem">
        Schiedsrichterkabine fegen
    </mat-list-item>
    <mat-list-item role="listitem">
        Wasser für den Schiedsrichter bereitstellen
    </mat-list-item>
    <mat-list-item role="listitem">
        Assistentenfahnen bereitlegen
    </mat-list-item>
    <mat-list-item role="listitem">
        Eckfahnen aufstellen
    </mat-list-item>
    <mat-list-item role="listitem">
        Bälle aufpumpen (0,9 Bar) und bereitlegen
    </mat-list-item>
    <mat-list-item role="listitem">
        Hütchen und Hemdchen für das Aufwärmen bereitlegen
    </mat-list-item>
</mat-list>
