export class Spieler {
  idspieler?: number;
  spielerzusatzId?: number;
  vorname: string;
  name: string;
  email: string;
  admin?: number;
  team?: number;
  geburtstag: string;
  bild: string;
  dabei?: number;
  extra?: number;
  datum?: string;
  wertung?: number;
  passwort?: String;
  nummer?: number;
  aktiv?: number;
  option?: number;
  summe?: number;
}

