<h3>Spieler ummelden</h3>


<mat-form-field appearance="fill">
    <mat-label>Spieler wählen</mat-label>
    <mat-select [(ngModel)]="selected">
        <mat-option *ngFor="let item of spieler" [value]="item.idspieler">
            {{item.name + " " + item.vorname}}
        </mat-option>
    </mat-select>
</mat-form-field>

<button mat-raised-button color="primary" (click)="melde(1)">Anmelden</button>
<button mat-raised-button color="accent" disabled>Abmelden</button>
