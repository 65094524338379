import { Component, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { BookStoreService } from '../shared/book-store.service';
import { FormBuilder, FormGroup, FormArray, Validators } from '@angular/forms';

import { Spielort } from '../shared/spielort';
import { Verein } from '../shared/verein';

@Component({
  selector: 'bm-create-club',
  templateUrl: './create-club.component.html',
  styleUrls: ['./create-club.component.css']
})
export class CreateClubComponent implements OnInit {
  spielort$: Observable<Spielort[]>
  vereinForm: FormGroup

  @Input() verein: Verein;
  @Input() editing = false;

  constructor(
    private fb: FormBuilder,
    private bs: BookStoreService
  ) { }

  ngOnInit(): void {
    this.spielort$ = this.bs.getAllOrte(0);
    this.initForm();
  }

  ngOnChanges() {
    this.initForm();
    this.setFormValues(this.verein);
  }

  private setFormValues(verein: Verein) {
    this.vereinForm.patchValue(verein)
  }

  private initForm() {
    if(this.vereinForm) { return; }
    this.vereinForm = this.fb.group({
      name: ['', Validators.required],
      logo: [''],
      idspielort: [''],
    })
  }

  submitForm() {
    const formValue = this.vereinForm.value;

    const newClub: Verein = {
      ...formValue
    };
    this.bs.createVerein(newClub);
    this.vereinForm.reset();
  }

}
