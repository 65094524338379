import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { BookStoreService } from '../../shared/book-store.service';
import { Spielleiter } from '../../shared/spielleiter';

@Component({
  selector: 'bm-schiedsrichter-details',
  templateUrl: './schiedsrichter-details.component.html',
  styleUrls: ['./schiedsrichter-details.component.css']
})
export class SchiedsrichterDetailsComponent implements OnInit {
  schiri$: Observable<Spielleiter>
  id: string

  constructor(
    private bs: BookStoreService,
    private route: ActivatedRoute,
    private router: Router
  ) { }

  ngOnInit(): void {
    const params = this.route.snapshot.paramMap;
    this.id = params.get('id');
    this.schiri$ = this.bs.getSingleSchiri(this.id);
  }

}
 //select avg(vtspielerspieltag.wertung) as wert, COUNT(vtspielerspieltag.wertung) as stimmen from vtspielerspieltag,spieltag natural join spielleiter where vtspielerspieltag.spieltag = spieltag.idspieltag and idspielleiter = 28
//and vtspielerspieltag.wertung >0
