import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DialogService } from 'src/app/service/dialog.service';
import { BookStoreService } from 'src/app/shared/book-store.service';
import { ReactiveFormsModule } from '@angular/forms';
import { Spieler } from 'src/app/shared/spieler';
import { Traindata } from 'src/app/shared/trainData';


@Component({
  selector: 'bm-create-data',
  templateUrl: './create-data.component.html',
  styleUrls: ['./create-data.component.css']
})
export class CreateDataComponent implements OnInit {

  spieler: Spieler[];
  trainData = this.fb.group({
    spieler:[''],
    cat: [''],
    distance: [''],
    date:[''],
  })


  constructor(
    private fb: FormBuilder,
    private bs: BookStoreService,
    private dialog: DialogService,
    ) { }

  ngOnInit(): void {
    this.bs.getAllSpieler(false,true).subscribe(res => {
      this.spieler = res
    })
  }

  getPoints(cat: string, distance: number): number {
    if(cat == "biking") {
      return distance*0.5
    } else if(cat == "jogging") {
      return distance*2
    } else if(cat== "swimming") {
      return distance*4
    } else if(cat =="ebiking" ) {
      return distance*0.25
    } else if(cat == "inliner") {
      return distance*1.5
    }
  }

  save()  {


    const td: Traindata = {
      ...this.trainData.value,
      points: this.getPoints(this.trainData.value.cat,this.trainData.value.distance),
      saison: this.bs.getCurSaison()
    }
    this.dialog.openConfirmDialog(td + " speichern?").afterClosed().subscribe(res => {
      console.log(td);

      if(res) {
        console.log("save " + td);
        this.bs.createTrainData(td).toPromise().then(res => {
          if(res) {
            this.dialog.openMessageDialog(res)
          }
        })
      }
    } )

 }
}
