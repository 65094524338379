<div fxLayout="row wrap" fxLayout.lt-sm="column" fxLayoutGap="1%" fxLayoutAlign="space-around stretch">

    <ng-container *ngIf="vereine$ | async as vereine; else loading">
        <bm-vereine-liste-item fxFlex="0 1 calc(33.3% - 1%)" fxFlex.lt-md="0 1 calc(50% - 1%)" fxFlex.lt-sm="100%" class="ui items" *ngFor="let v of vereine" [vereine]="v" [routerLink]="v.idVerein"></bm-vereine-liste-item>

        <p *ngIf="!vereine.length">Es wurden noch keine Vereine eingetragen.</p>
    </ng-container>

    <ng-template #loading>
        <div class="ui active dimmer">
            <div class="ui large text loader">Daten werden geladen...</div>
        </div>
    </ng-template>

</div>
