<mat-card class="example-card">
  <mat-card-header>
      <div mat-card-avatar>
          <ng-container *ngIf="spieler.bild; else elseBlock">
              <img class="ui mini image" src="../../../assets/spielerFotos/{{spieler.bild}}"> </ng-container>
      </div>
      <mat-card-title>{{ spieler.name + ', ' + spieler.vorname + ": " + spieler.summe + "km" }}</mat-card-title>

  </mat-card-header>
  <img mat-card-image>

</mat-card>


<ng-template #passiv>
  <mat-icon>highlight_off</mat-icon>
</ng-template>



<ng-template #elseBlock><img class="ui tiny image " src="../../../assets/spielerFotos/ohne.jpg "></ng-template>
